import { AccountService } from './../_services/account.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppBranch } from '../models/app';

@Component({
  selector: 'app-switch-branch-dialog',
  templateUrl: './switch-branch-dialog.component.html',
  styleUrls: ['./switch-branch-dialog.component.scss']
})
export class SwitchBranchDialogComponent implements OnInit {

  currentBranch: AppBranch;
  branches: AppBranch[];
  filteredValues: AppBranch[];
  selectedValue: any;

  constructor(
    public dialogRef: MatDialogRef<SwitchBranchDialogComponent>,
    private _accountService: AccountService
  ) {
    this.currentBranch = this._accountService.getCurrentBranch();
    const currentUser = this._accountService.getCurrentUser();
    if (currentUser?.customer) {
      this.branches = currentUser.customer.branches;
    }
  }

  get IsValueSelected(): boolean {
    return (this.selectedValue && this.selectedValue.id);
  }

  ngOnInit(): void {
    this.filterValues();
  }

  filterValues() {
    if (this.selectedValue && !this.selectedValue.id) {
      this.filteredValues = this._filter(this.selectedValue);
      return;
    }

    this.filteredValues = this.branches;
  }

  displayFn(option: AppBranch): string {
    return option && option.name ? option.name : '';
  }

  private _filter(name: string): AppBranch[] {
    const filterValue = name.toLowerCase();

    return this.branches.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }

  doAction() {
    if (this.IsValueSelected) {
      this._accountService.switchBranch(this.selectedValue.id)
    }
    this.dialogRef.close({ event: 'Switch' });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}
