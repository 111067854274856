import { NotificationsDialogComponent } from './../dialogs/notifications-dialog/notifications-dialog.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from 'src/app/_services';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  private _userLoggedOut = true;
  constructor(private _accountService: AccountService, public dialog: MatDialog) {
    this._accountService.user.subscribe(x => {
      const wasLoggedOut = this._userLoggedOut;
      this._userLoggedOut = !(Boolean(x) && x.expiration > new Date());

      if (!this._userLoggedOut && wasLoggedOut && x.notifications?.length) {
        this.dialog.open(NotificationsDialogComponent, {
          width: '450px',
          data: x.notifications,
        });
      }
    });
  }

  ngOnInit(): void {
  }

}
