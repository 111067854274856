<as-split unit="percent" [direction]="direction" gutterAriaLabel="adjustable divider between two views"
    (dragStart)="dragStart()" (dragEnd)="dragEnd('percent', $event)" (gutterClick)="toggleFile($event)"
    [useTransition]="true" #split="asSplit">
    <as-split-area #area1="asSplitArea">
        <div class="main">
            <div class="container">
                <router-outlet></router-outlet>
            </div>
        </div>
    </as-split-area>
    <as-split-area #area2="asSplitArea">
        <div class="curtain" *ngIf="dragging"></div>
        <div class="pdf">
            <file-viewer [documentId]="documentId"></file-viewer>
        </div>
    </as-split-area>
</as-split>