import { NotificationModel } from './notification';
export class AppBranch {
    constructor(other: AppBranch) {
        this.id = other.id;
        this.name = other.name;
        this.isMainBranch = other.isMainBranch;
        this.stateCode = other.stateCode;
        this.role = other.role;
    }

    id: string;
    name: string;
    stateCode: string;
    isMainBranch: boolean;
    role: string;
}

export class AppCustomer {
    constructor(other: AppCustomer) {
        this.id = other.id;
        this.name = other.name;
        this.code = other.code;
        this.branches = other.branches.map(x => new AppBranch(x));
        this.useCalculationLimit = other.useCalculationLimit;
        this.properties = other.properties?.map(x => new CustomerProperty(x));
        this.hasInvoiceImport = other.hasInvoiceImport;
    }

    id: string;
    name: string;
    code: string;
    branches: AppBranch[];
    useCalculationLimit: boolean;
    properties: CustomerProperty[];
    hasInvoiceImport: boolean;
}

export class CustomerProperty {
    constructor(other: CustomerProperty) {
        this.group = other.group;
        this.key = other.key;
        this.value = other.value;
    }
    group: string;
    key: string;
    value: string;
}

export class AppUser {
    constructor(other: AppUser) {
        this.id = other.id;
        this.username = other.username;
        this.name = other.name;
        this.token = other.token;
        this.expiration = new Date(other.expiration);
        this.roles = other.roles;
        this.notifications = other.notifications;
        if (other.customer) {
            this.customer = new AppCustomer(other.customer)
        }
        if (other.customers) {
            this.customers = other.customers.map(x => new AppCustomer(x));
        }
        if (other.modules) {
            this.modules = other.modules;
        }
    }

    id: string;
    username: string;
    password: string;
    name: string;
    token: string;
    expiration: Date;
    roles: string[];
    customer?: AppCustomer;
    customers?: AppCustomer[];
    notifications?: NotificationModel[];
    modules?: string[];

    get IsTemporary(): boolean {
        return this.roles.indexOf('Temporary') !== -1;
    }
    get IsAdmin(): boolean {
        return this.roles.indexOf('Admin') !== -1;
    }
    get HasBranches(): boolean {
        return this.customer?.branches && this.customer.branches.length > 1;
    }
    public hasLicense(module: string) {
        return this.modules && this.modules.indexOf(module) !== -1;
    }
}
