import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { SubscriptionHelper } from "../_helpers/subscription-helper";


export interface ServiceClass {
    id: string;
}

export abstract class ServiceBase<T extends ServiceClass> extends SubscriptionHelper {
    protected abstract http: HttpClient;
    protected abstract urlAction: string;
    protected addCallback: (entity: T) => void;
    protected updateCallback: (entity: T) => void;
    protected deleteCallback: (id: string) => void;

    protected get BaseUrl(): string {
        return `${environment.apiUrl}${this.urlAction}`;
    }

    constructor() {
        super();
    }

    public get(url?: string, queryParams?: string[]): Promise<T[]> {
        const params = `?${queryParams?.join("&") ?? ""}`;
        return this.http.get<T[]>(`${url ?? this.BaseUrl}${params}`).toPromise();
    }

    public add(obj: T): Promise<T> {
        const promise = this.http.post<T>(`${this.BaseUrl}`, obj).toPromise();
        promise.then(x => {
            if (this.addCallback) {
                this.addCallback(x);
            }
        })
        return promise;
    }

    public update(obj: T): Promise<T> {
        const promise = this.http.put<T>(`${this.BaseUrl}/${obj.id}`, obj).toPromise();
        promise.then(x => {
            if (this.updateCallback) {
                this.updateCallback(x);
            }
        })
        return promise;
    }

    public delete(id: string): Promise<Object> {
        const promise = this.http.delete(`${this.BaseUrl}/${id}`).toPromise();
        promise.then(x => {
            if (this.deleteCallback) {
                this.deleteCallback(id);
            }
        })
        return promise;
    }
}

export class Id implements ServiceClass {
    public id: string;
}