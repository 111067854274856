import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { routes } from '../app-routing.module';
import { AccountService } from '../_services';

@Component({
  selector: 'app-redirector',
  templateUrl: './redirector.component.html',
  styleUrls: ['./redirector.component.scss']
})
export class RedirectorComponent implements OnInit {

  constructor(
    private accountService: AccountService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const user = this.accountService.userValue;
    if (user.hasLicense('Reporting')) {
      this.router.navigate(['reporting'])
    } else {
      this.router.navigate(['assessment'])
    }
  }
}
