import { environment } from './../environments/environment';
import { ProjectExportDialogComponent } from './home/dialogs/project-export-dialog/project-export-dialog.component';
import { ExcelExportDialogComponent } from './home/dialogs/excel-export-dialog/excel-export-dialog.component';
import { AssetsService } from './_services/assets.service';
import { StakeholderService } from './_services/data/stakeholder.service';
import { CustomerSystemService } from './_services/data/customer-system.service';
import { FundingFactService } from './_services/data/funding-fact.service';
import { LockService } from './_services/lock-service';
import { Router } from '@angular/router';
import { SwitchCustomerDialogComponent } from './switch-customer-dialog/switch-customer-dialog.component';
import { SwitchBranchDialogComponent } from './switch-branch-dialog/switch-branch-dialog.component';
import { Component, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppBranch, AppUser } from './models/app';
import { AccountService } from './_services';
import * as fileSaver from 'file-saver';
import { Workbook, Worksheet } from 'exceljs';
import { first } from 'rxjs/operators';
import { FundingFactDefinitionsService } from './_services/funding-fact-definitions.service';
import { FundingFactConstants } from './models/funding-fact';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent {
    @HostListener('window:beforeunload', ['$event'])
    public beforeunloadHandler($event) {
        this._lockService.unload().then();
    }

    User: AppUser;
    Branch: AppBranch;
    now: Date = new Date();

    appTitle = environment.title;

    constructor(
        private _accountService: AccountService,
        private _dialog: MatDialog,
        private _router: Router,
        private _webSocketService: LockService,
        private _fundingFactDefinitionsService: FundingFactDefinitionsService,
        private _customerSystemsService: CustomerSystemService,
        private _stakeholdersService: StakeholderService,
        private _fundingFactService: FundingFactService,
        private _lockService: LockService,
        private _assetsService: AssetsService
    ) {
        this._accountService.user.subscribe(x => this.User = x);
        this._accountService.branch.subscribe(x => this.Branch = x);
        this._accountService.user.pipe(first(x => Boolean(x?.token))).subscribe(x => this._webSocketService.startConnection())
    }

    logout() {
        this._accountService.logout();
    }

    switchBranch() {
        const dialogRef = this._dialog.open(SwitchBranchDialogComponent, {
            width: '450px',
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result?.event == 'Switch') {
                this._router.navigate(['/']);
            }
        });
    }

    switchCustomer() {
        const dialogRef = this._dialog.open(SwitchCustomerDialogComponent, {
            width: '450px',
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result?.event == 'Switch') {
                this._router.navigate(['/']);
            }
        });
    }

    exportExcel(): void {
        this._dialog.open(ExcelExportDialogComponent, {
            width: '450px',
        });
    }

    exportProjects(): void {
        this._dialog.open(ProjectExportDialogComponent, {
            width: '450px',
        });
    }

    exportAssessment() {
        const branches = this.User.customer.branches;
        const promises = branches.map(x => this._fundingFactService.getWithCriteria(x.id));

        Promise.all([
            this._fundingFactDefinitionsService.getWithCriteria(),
            Promise.all(promises),
        ]).then(data => {
            const fundingFactDefinitions = data[0];
            const branchFFs = data[1];
            const workbook = new Workbook();
            let i = 0;
            for (let i = 0; i < branches.length; i++) {
                const fundingFacts = branchFFs[i];

                const sheet = workbook.addWorksheet('Daten ' + (1 + i));

                sheet.getCell(1, 2).value = branches[i].name;

                let row = 3;
                let col = 1;
                sheet.getColumn(col).hidden = true;
                sheet.getCell(row, col++).value = 'Id';
                sheet.getCell(row, col++).value = 'Fördertatbestand';
                sheet.getCell(row, col++).value = 'Text';
                sheet.getCell(row, col++).value = 'Typ';
                sheet.getCell(row, col++).value = 'Kriterium';
                sheet.getCell(row, col++).value = 'Ist';
                sheet.getCell(row, col++).value = 'Soll';
                sheet.getRow(row).font = { bold: true }
                const lastCol = col - 1;
                row++;
                if (fundingFactDefinitions) {
                    for (const fundingFactDefinition of fundingFactDefinitions) {
                        if (fundingFactDefinition.code < 'FTB2' || fundingFactDefinition.code > 'FTB6') {
                            continue;
                        }
                        const fundingFact = fundingFacts.find(x => x.code === fundingFactDefinition.code);
                        col = 2;
                        sheet.getCell(row, col++).value = fundingFactDefinition.code;
                        sheet.getCell(row, col++).value = FundingFactConstants.shortNames[fundingFactDefinition.code.replace('.', '_')];
                        col++;
                        sheet.getCell(row, col++).value = 'Kriterium';
                        sheet.getCell(row, col++).value = 'IST';
                        sheet.getCell(row, col++).value = 'SOLL';

                        sheet.getRow(row).font = { italic: true }
                        row++;
                        if (fundingFactDefinition.criteria) {
                            let lastType = '';
                            for (const criteriumDefinition of fundingFactDefinition.criteria) {
                                const criterium = fundingFact?.criteria?.find(x => x.code === criteriumDefinition.code);
                                if (lastType && lastType !== criteriumDefinition.type) {
                                    row++;
                                }
                                lastType = criteriumDefinition.type;
                                col = 1;
                                sheet.getCell(row, col++).value = criteriumDefinition.id;
                                sheet.getCell(row, col++).value = '';
                                sheet.getCell(row, col++).value = criteriumDefinition.name;
                                sheet.getCell(row, col++).value = criteriumDefinition.type;
                                sheet.getCell(row, col++).value = criteriumDefinition.code;
                                sheet.getCell(row, col++).value = criterium?.actualFulfillment?.value ?? 0;
                                if (criterium?.actualFulfillment?.hint) {
                                    sheet.getCell(row, col - 1).note = criterium.actualFulfillment.hint;
                                }
                                sheet.getCell(row, col++).value = criterium?.targetFulfillment?.value ?? 0;
                                if (criterium?.targetFulfillment?.hint) {
                                    sheet.getCell(row, col - 1).note = criterium.targetFulfillment.hint;
                                }
                                row++;
                            }
                        }
                        row++;
                    }
                }
            }
            /*
            {
                const sheet = workbook.addWorksheet('Systeme');

                let row = 1;
                let col = 1;
                sheet.getColumn(col).hidden = true;
                sheet.getCell(row, col++).value = 'Id';
                sheet.getCell(row, col++).value = 'Typ';
                sheet.getCell(row, col++).value = 'Name';
                sheet.getCell(row, col++).value = 'Hersteller';
                sheet.getCell(row, col++).value = 'Jahr Beschaffung';
                sheet.getCell(row, col++).value = 'Jahr Einführung';
                sheet.getRow(row).font = { bold: true }
                row++;
                if (customerSystems) {
                    for (const customerSystem of customerSystems) {
                        col = 1;
                        sheet.getCell(row, col++).value = customerSystem.id;
                        sheet.getCell(row, col++).value = customerSystem.type;
                        sheet.getCell(row, col++).value = customerSystem.name;
                        sheet.getCell(row, col++).value = customerSystem.vendor;
                        sheet.getCell(row, col++).value = customerSystem.aquisitionYear;
                        sheet.getCell(row, col++).value = customerSystem.rolloutYear;
                        row++;
                    }
                }
            }
            {
                const sheet = workbook.addWorksheet('Stakeholder');

                let row = 1;
                let col = 1;
                sheet.getColumn(col).hidden = true;
                sheet.getCell(row, col++).value = 'Id';
                sheet.getCell(row, col++).value = 'Name';
                sheet.getCell(row, col++).value = 'Vorname';
                sheet.getCell(row, col++).value = 'Titel';
                sheet.getCell(row, col++).value = 'Kürzel';
                sheet.getCell(row, col++).value = 'Position';
                sheet.getCell(row, col++).value = 'E-Mail';
                sheet.getCell(row, col++).value = 'Telefon';
                sheet.getRow(row).font = { bold: true }
                row++;
                if (stakeholders) {
                    for (const stakeholder of stakeholders) {
                        col = 1;
                        sheet.getCell(row, col++).value = stakeholder.id;
                        sheet.getCell(row, col++).value = stakeholder.name;
                        sheet.getCell(row, col++).value = stakeholder.firstName;
                        sheet.getCell(row, col++).value = stakeholder.title;
                        sheet.getCell(row, col++).value = stakeholder.code;
                        sheet.getCell(row, col++).value = stakeholder.position;
                        sheet.getCell(row, col++).value = stakeholder.mail;
                        sheet.getCell(row, col++).value = stakeholder.phone;
                        row++;
                    }
                }
            }
            */
            const filename = `KHZG-${this.User.customer.name}-${this.Branch.name}-${new Date().valueOf()}.xlsx`;
            workbook.xlsx.writeBuffer().then(out => {
                const blob = new Blob([out], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                fileSaver.saveAs(blob, filename + '-' + new Date().valueOf() + '.xlsx');
            });
        });
    }
}