import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Stakeholder } from 'src/app/models/stakeholder';
import { AccountService } from '..';
import { DataServiceBase } from './data-service-base';

@Injectable({
  providedIn: 'root'
})
export class StakeholderService extends DataServiceBase<Stakeholder> {
  protected dataType = 'stakeholder';
  private stakeholderSubject = new BehaviorSubject<Stakeholder[]>(undefined);
  public stakeholder: Observable<Stakeholder[]>;

  public get stakeholderList(): Stakeholder[] {
    return this.stakeholderSubject.value;
  }

  constructor(protected http: HttpClient, private accountService: AccountService) {
    super();
    this.addCallback = (x: Stakeholder) => { this.refresh(); };
    this.updateCallback = (x: Stakeholder) => { this.refresh(); };
    this.deleteCallback = (x: string) => { this.refresh(); };
    this.stakeholder = this.stakeholderSubject.asObservable();
    accountService.branch.subscribe(x => {
      this.branch = x
      this.refresh();
    });
    accountService.user.subscribe(x => {
      this.customer = x?.customer
      this.refresh();
    });
  }

  public refresh() {
    if (this.branch && this.customer) {
      this.get().then(x => {
        this.stakeholderSubject.next(x);
      });
    }
  }
}
