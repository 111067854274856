import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent implements OnInit {
  type: string;
  message: string;
  details: string[];

  constructor(
    public sbRef: MatSnackBarRef<SnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {
    if (this.data?.error?.message) {
      this.type = 'error';
      this.message = this.data.error.message;
      if (this.data.error.details) {
        this.details = this.data.error.details;
      }
    } else if (this.data?.error?.title) {
      this.type = 'error';
      this.message = this.data.error.title;
    }
  }

  ngOnInit(): void {
  }

}
