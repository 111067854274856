import { StaticListHelper } from 'src/app/_helpers/static-list-helper';
import { CostLineConstants } from './../../../models/cost-line';
import { AccountService } from './../../../_services/account.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Workbook } from 'exceljs';
import { Project, ProjectConstants } from 'src/app/models/project';
import { ProjectService } from 'src/app/_services/data/project.service';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-project-export-dialog',
  templateUrl: './project-export-dialog.component.html',
  styleUrls: ['./project-export-dialog.component.scss']
})
export class ProjectExportDialogComponent implements OnInit {
  statusMsg = 'Export wird gestartet';
  progress = 20;
  constructor(
    public dialogRef: MatDialogRef<ProjectExportDialogComponent>,
    private _projectsService: ProjectService,
    private _accountsService: AccountService
  ) {
  }

  ngOnInit(): void {
    this.status('Daten laden...', 5);
    this._projectsService.get().then(projects => {
      this.status('Daten schreiben...', 15);
      this.export(projects).then(_ => {
        this.status('Export abgeschlossen', 100);
      })
    })
  }

  private export(projects: Project[]): Promise<any> {
    return new Promise<any>(resolve => {
      const count = projects.length;
      const step = Math.floor(80 / count / 2);
      const workbook = new Workbook();
      {
        const sheet = workbook.addWorksheet('Projekte');
        const branches = this._accountsService.getCurrentUser().customer?.branches ?? [];
        let row = 1;
        let col = 1;
        sheet.getColumn(col).hidden = true;
        sheet.getCell(row, col++).value = 'Id';
        sheet.getCell(row, col++).value = 'Titel';
        sheet.getCell(row, col++).value = 'Projekttyp';
        sheet.getCell(row, col++).value = 'Gesamtkosten';
        sheet.getCell(row, col++).value = 'IS Kosten';
        for (const type of Object.keys(CostLineConstants.Types)) {
          sheet.getCell(row, col++).value = CostLineConstants.Types[type];
        }
        for (const type of Object.keys(CostLineConstants.ApplicationTypes)) {
          sheet.getCell(row, col++).value = CostLineConstants.ApplicationTypes[type] ?? 0;
        }
        sheet.getCell(row, col++).value = 'Kosten ab Jahr';
        sheet.getCell(row, col++).value = 'Hersteller';
        sheet.getCell(row, col++).value = 'Produkt';
        sheet.getCell(row, col++).value = 'Vorhabensbeschreibung';
        sheet.getCell(row, col++).value = 'Maßnahmen Informationssicherheit';
        sheet.getCell(row, col++).value = 'Fördertatbestände';
        sheet.getCell(row, col++).value = 'Schlagworte';
        sheet.getCell(row, col++).value = 'Kalkulation';
        sheet.getCell(row, col++).value = 'Priorität';
        sheet.getCell(row, col++).value = 'Status';
        sheet.getCell(row, col++).value = 'Förderwahrscheinlichkeit';
        sheet.getCell(row, col++).value = 'Projektant';

        for (let i = 1; i < 12; i++) {
          sheet.getCell(row, col++).value = `FTB${i}`;
        }
        for (const branch of branches) {
          sheet.getCell(row, col++).value = branch.name;
        }

        sheet.getCell(row, col++).value = 'Eigenanteil';
        sheet.getCell(row, col++).value = 'Investive Kosten';
        sheet.getCell(row, col++).value = 'Laufende Kosten';
        sheet.getCell(row, col++).value = 'Förderfähige Kosten';
        sheet.getCell(row, col++).value = 'Sortierungs-ID';
        sheet.getRow(row).font = { bold: true }
        row++;

        const mappings = StaticListHelper.ToList(CostLineConstants.TypeMappings);

        for (const project of projects) {
          col = 1;
          sheet.getColumn(col).hidden = true;
          sheet.getCell(row, col++).value = project.id;
          sheet.getCell(row, col++).value = project.title;
          sheet.getCell(row, col++).value = ProjectConstants.Types[project.type];
          sheet.getCell(row, col++).value = project.totalCosts;
          sheet.getCell(row, col++).value = project.securityCosts;
          for (const type of Object.keys(CostLineConstants.Types)) {
            sheet.getCell(row, col++).value = project.costsByType[type] ?? 0;
          }
          for (const type of Object.keys(CostLineConstants.ApplicationTypes)) {
            const types = mappings.filter(x => x.value === type);
            let sum = 0;
            for (const ct of Object.keys(project.costsByType).filter(x => types.find(y => x === y.key))) {
              sum += project.costsByType[ct];
            }
            sheet.getCell(row, col++).value = sum ?? 0;
          }
          sheet.getCell(row, col++).value = project.firstCostYear;
          sheet.getCell(row, col++).value = project.productVendor;
          sheet.getCell(row, col++).value = project.productName;
          sheet.getCell(row, col++).value = project.description;
          sheet.getCell(row, col++).value = project.descriptionSecurity;
          sheet.getCell(row, col++).value = project.fundingFactCodes?.map(x => {
            const index = x.indexOf('.');
            return index < 0 ? x : x.substr(0, index);
          }).join(', ');
          sheet.getCell(row, col++).value = project.keywords?.map(x => x.name).join(', ');
          sheet.getCell(row, col++).value = project.includeInCalculation ? 'ja' : 'nein';
          sheet.getCell(row, col++).value = ProjectConstants.Priorities[project.priority];
          sheet.getCell(row, col++).value = ProjectConstants.Status[project.status];
          sheet.getCell(row, col++).value = ProjectConstants.FundingChances[project.fundingChance];
          sheet.getCell(row, col++).value = project.assignee;
          for (let i = 1; i < 12; i++) {
            const ratio = project.fundingFactsWithRatio[`FTB${i}${i === 2 || i === 3 ? '.1' : ''}`] / 100
            sheet.getCell(row, col++).value = ratio ? ratio : '';
          }
          for (const branch of branches) {
            const ratio = project.branches[branch.id] / 100
            sheet.getCell(row, col++).value = ratio ? ratio : '';
          }
          sheet.getCell(row, col++).value = ((project.funding['EIGENE']) ?? 0) / 100;
          sheet.getCell(row, col++).value = project.khzgCostsOnce ?? 0;
          sheet.getCell(row, col++).value = project.khzgCostsRepeating ?? 0;
          sheet.getCell(row, col++).value = project.khzgCosts ?? 0;
          sheet.getCell(row, col++).value = project.sortCode ?? "";
          this.progress += step;
          row++;
        }
      }
      {
        const sheet = workbook.addWorksheet('Kosten');
        const branches = this._accountsService.getCurrentUser().customer?.branches ?? [];
        let row = 1;
        let col = 1;
        sheet.getColumn(col).hidden = true;
        sheet.getCell(row, col++).value = 'Id';
        sheet.getColumn(col).hidden = true;
        sheet.getCell(row, col++).value = 'Projekt Id';
        sheet.getCell(row, col++).value = 'Projekt Titel';
        sheet.getCell(row, col++).value = 'Beschreibung';
        sheet.getCell(row, col++).value = 'Typ';
        sheet.getCell(row, col++).value = 'Wert';
        sheet.getCell(row, col++).value = 'Monat';
        sheet.getCell(row, col++).value = 'Jahr';
        sheet.getCell(row, col++).value = 'Intervall';
        sheet.getCell(row, col++).value = 'Laufzeit';
        sheet.getCell(row, col++).value = 'Informationssicherheit';
        sheet.getRow(row).font = { bold: true }
        row++;

        for (const project of projects) {
          for (const costLine of project.costs ?? []) {
            col = 1;
            sheet.getColumn(col).hidden = true;
            sheet.getCell(row, col++).value = costLine.id;
            sheet.getColumn(col).hidden = true;
            sheet.getCell(row, col++).value = project.id;
            sheet.getCell(row, col++).value = project.title;
            sheet.getCell(row, col++).value = costLine.title;
            sheet.getCell(row, col++).value = CostLineConstants.Types[costLine.type];
            sheet.getCell(row, col++).value = costLine.valueTotal;
            sheet.getCell(row, col++).value = costLine.month;
            sheet.getCell(row, col++).value = costLine.year;
            sheet.getCell(row, col++).value = CostLineConstants.Intervals[costLine.interval];
            sheet.getCell(row, col++).value = costLine.runtime;
            sheet.getCell(row, col++).value = costLine.isSecurity ? 'TRUE' : 'FALSE';
            row++;
          }
          this.progress += step;
        }
      }
      const customer = this._accountsService.getCurrentUser().customer.name;
      const filename = `KHZG-Projekt-Export-${customer}-${new Date().valueOf()}.xlsx`;
      workbook.xlsx.writeBuffer().then(out => {
        const blob = new Blob([out], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fileSaver.saveAs(blob, filename);
        resolve(true);
      });
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  private status(msg: string, progress: number) {
    this.statusMsg = msg;
    this.progress = progress;
  }
}
