import { Rating } from './rating';
import { ServiceClass } from './../_services/service-base';
import { KeyValuePair } from '../_helpers/static-list-helper';
export class FundingFact implements ServiceClass {
    public id: string;
    public code: string;
    public name: string;
    public description: string;
    public note?: string;
    public criteria: Criterium[];

    public valuePatient: Rating;
    public valuePhysician: Rating;
    public valueNurse: Rating;
    public valueReferrer: Rating;
    public valuePostTreatment: Rating;
    public valueEmr: Rating;
    public qualityIncrease: Rating;
    public efficiencyIncreaseMed: Rating;
    public efficiencyIncreaseAdm: Rating;
    public costReduction: Rating;
    public revenueIncrease: Rating;
    public complianceAndSecurity: Rating;
}

export class Criterium implements ServiceClass {
    public id: string;
    public code: string;
    public name: string;
    public type: string;
    public note?: string;

    public actualFulfillment: Rating;
    public targetFulfillment: Rating;
    public complexityInterop: Rating;
    public complexityProcess: Rating;
    public relevantForTi: Rating;
    public relevantForCdr: Rating;
}

export class FundingFactConstants {
    public static shortNames = {
        FTB1: 'Notaufnahme',
        FTB2: 'Patientenportale',
        FTB2_1: 'Digitales Aufnahmemanagement',
        FTB2_2: 'Digitales Behandlungsmanagement',
        FTB2_3: 'Digitales Entlass- und Überleitungsmanagement',
        FTB3: 'Digitale Pflege- und Behandlungsdokumentation',
        FTB3_1: 'Digitale Dokumentation',
        FTB3_2: 'Automatisierte und sprachbasierte Dokumentation',
        FTB4: 'Entscheidungsunterstützungssystem',
        FTB5: 'Medikationsmanagement',
        FTB6: 'Leistungsanforderung',
        FTB7: 'Leistungsabstimmung und Cloud-Computing',
        FTB8: 'Versorgungsnachweis für Betten',
        FTB9: 'Telemedizinische Netzwerke',
        FTB10: 'Informationssicherheit',
        FTB11: 'Anpassung von Patientenzimmern an Behandlungserfordernisse',
    }

    public static get fundingFacts(): {code: string, title: string}[] {
        return Object.keys(this.shortNames).filter(x => x.indexOf("_") === -1).map(x => ({ code: x, title: this.shortNames[x] }))
    }
}