<h1 *ngIf="model.name" mat-dialog-title><strong>{{model.name}}</strong></h1>
<h1 *ngIf="!model.name" mat-dialog-title><strong>Stakeholder hinzufügen</strong></h1>
<div mat-dialog-content>
  <div style="display: flex; flex-direction: column;" *ngIf="action != 'Delete'; else elseTemplate">
    <mat-form-field appearance="fill">
      <input placeholder="Name" matInput [(ngModel)]="model.name" [disabled]="action === 'View'" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <input placeholder="Vorname" matInput [(ngModel)]="model.firstName" [disabled]="action === 'View'" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <input placeholder="Titel" matInput [(ngModel)]="model.title" [disabled]="action === 'View'" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <input placeholder="Kürzel" matInput [(ngModel)]="model.code" [disabled]="action === 'View'" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <input placeholder="Position" matInput [(ngModel)]="model.position" [disabled]="action === 'View'" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <input placeholder="Mail" matInput [(ngModel)]="model.mail" [disabled]="action === 'View'" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <input placeholder="Telefon" matInput [(ngModel)]="model.phone" [disabled]="action === 'View'" />
    </mat-form-field>
  </div>
  <ng-template #elseTemplate>
    Soll der Stakeholder <strong>{{model.name}}</strong> wirklich gelöscht werden?
  </ng-template>
</div>
<div mat-dialog-actions style="display: flex; flex-direction: row; justify-content: flex-end;">
  <button [disabled]="action === 'View'" type="submit" mat-button (click)="doAction()">OK</button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Abbrechen</button>
</div>
