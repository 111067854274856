<h1 mat-dialog-title><strong>Änderungen speichern?</strong></h1>
<div mat-dialog-content>
    <p>Die Änderungen wurden nicht gespeichert. Wie möchten Sie fortfahren?</p>
</div>
<div mat-dialog-actions style="display: flex; flex-direction: row; justify-content: flex-end;">
    <button type="submit" mat-button (click)="doAction('Save')" mat-button color="primary" matTooltip="Speichern">
        <div style="display: flex; flex-direction: column; align-items: center;">
            <mat-icon>save</mat-icon>
            <span style="font-size: x-small;">Speichern</span>
        </div>
    </button>
    <button mat-button (click)="doAction('SaveAndRelease')" mat-button color="primary"
        matTooltip="Speichern und entsperren">
        <div style="display: flex; flex-direction: column; align-items: center;">
            <mat-icon>lock</mat-icon>
            <span style="font-size: x-small;">Entsperren</span>
        </div>
    </button>
    <button mat-button (click)="doAction('Dismiss')" mat-button color="accent" matTooltip="Änderungen verwerfen">
        <div style="display: flex; flex-direction: column; align-items: center;">
            <mat-icon>check</mat-icon>
            <span style="font-size: x-small;">Verwerfen</span>
        </div>
    </button>
    <button mat-button (click)="doAction('Cancel')" mat-button color="warn" matTooltip="Abbrechen">
        <div style="display: flex; flex-direction: column; align-items: center;">
            <mat-icon>cancel</mat-icon>
            <span style="font-size: x-small;">Abbrechen</span>
        </div>
    </button>
</div>