import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Stakeholder } from 'src/app/models/stakeholder';
import { TableDialogData } from 'src/app/models/table-dialog-data';

@Component({
  selector: 'app-stakeholder-dialog',
  templateUrl: './stakeholder-dialog.component.html',
  styleUrls: ['./stakeholder-dialog.component.scss']
})
export class StakeholderDialogComponent implements OnInit {
  public action: string;
  public model: Stakeholder;

  constructor(
    public dialogRef: MatDialogRef<StakeholderDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: TableDialogData<Stakeholder>
  ) {
    this.model = JSON.parse(JSON.stringify(this.data.data));
    this.action = this.data.action;
  }

  ngOnInit(): void {
  }

  doAction() {
    this.dialogRef.close({ event: this.action, data: this.model });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}
