import { AfterViewInit, Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { SplitComponent, SplitAreaDirective } from 'angular-split';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss']
})
export class InboxComponent implements OnInit, AfterViewInit {
  public dragging: boolean = false;

  @ViewChild('split') split: SplitComponent
  @ViewChild('area1') area1: SplitAreaDirective
  @ViewChild('area2') area2: SplitAreaDirective

  protected documentId = "";

  direction = 'horizontal'
  sizes = {
    initial: {
      area1: 60,
      area2: 40,
    },
    percent: {
      area1: 60,
      area2: 40,
    },
    pixel: {
      area1: 120,
      area2: '*',
      area3: 160,
    },
  }

  constructor(private previewService: PreviewService) {
    this.previewService.itemId.subscribe(id => {
      try {
        if (!id) {
          this.documentId = null;
          this.hideFile();
        } else {
          this.documentId = id;
          this.unhideFile();
        }
      } catch (err) {
        console.error(err);
      }
    });
    this.previewService.selectItem("");
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {

  }

  public itemSelected(item: any) {
    this.documentId = item.id;
  }

  dragStart() {
    this.dragging = true;
  }

  dragEnd(unit, { sizes }) {
    this.dragging = false;
    if (unit === 'percent') {
      this.sizes.percent.area1 = sizes[0]
      this.sizes.percent.area2 = sizes[1]
    } else if (unit === 'pixel') {
      this.sizes.pixel.area1 = sizes[0]
      this.sizes.pixel.area2 = sizes[1]
    }
  }

  toggleFile(e: { gutterNum: number; sizes: Array<number> }) {
    if (e.sizes[1] === 0) {
      this.unhideFile();
    } else {
      this.sizes.initial.area2 = e.sizes[1];
      this.hideFile();
    }
  }

  hideFile() {
    if (this.area1 && this.area2) {
      this.area2.size = 0;
      this.area1.size = 100;
    }
  }

  unhideFile() {
    this.area2.size = this.sizes.initial.area2;
    this.area1.size = 100 - this.sizes.initial.area2;
  }
}

@Injectable({
  providedIn: 'root'
})
export class PreviewService {
  private _text = new BehaviorSubject<string>('');
  readonly text = this._text.asObservable();

  private _itemId = new BehaviorSubject<string>('');
  readonly itemId = this._itemId.asObservable();

  constructor() {
  }

  setText(text: string) {
    this._text.next(text);
  }

  selectItem(id: string) {
    if (this._itemId.getValue() !== id) {
      this._itemId.next(id);
    }
  }

  public reload() {
    const id = this._itemId.getValue();
    this._itemId.next("");
    setTimeout(() => {
      this.selectItem(id);
    }, 600);
  }
}