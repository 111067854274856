import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InfoDocument } from 'src/app/models/info-document';
import { environment } from 'src/environments/environment';
import { AccountService } from '../account.service';
import { DataService } from '../data/data-service-base';
import { ServiceBase, ServiceClass } from '../service-base';
import { DocumentClass } from 'src/app/models/document';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(protected http: HttpClient, private accountService: AccountService) {
  }

  get BaseUrlFiles(): string {
    return `${environment.apiUrl}/reporting/${this.accountService.getCurrentUser().customer.id}/files`;
  }

  get BaseUrlDocs(): string {
    return `${environment.apiUrl}/reporting/${this.accountService.getCurrentUser().customer.id}/documents`;
  }
  get(type: string, id: string): Promise<any> {
    return this.http.get<any>(`${this.BaseUrlFiles}/${(type ? `${type}/` : '')}${id}/info`).toPromise();
  }

  getById(id: string): Promise<DocumentClass> {
    return this.http.get<DocumentClass>(`${this.BaseUrlDocs}/${id}`).toPromise();
  }

  update(model: DocumentClass): Promise<DocumentClass> {
    return this.http.put<DocumentClass>(`${this.BaseUrlDocs}/${model.id}`, model).toPromise();
  }

  downloadFile(type: string, id: string): Promise<any> {
    return this.http.get(`${this.BaseUrlFiles}/${(type ? `${type}/` : '')}${id}`, { responseType: 'blob' }).toPromise();
  }

  uploadKbFile(model: InfoDocument, name: string, data: Blob): Promise<InfoDocument> {
    const formData = new FormData();
    formData.append('model', JSON.stringify(model));
    formData.append('file', data, name);
    return this.http.post<InfoDocument>(`${this.BaseUrlFiles}/kb`, formData).toPromise();
    //, { reportProgress: true, observe: 'events' }
  }

  getKbFiles(): Promise<InfoDocument[]> {
    return this.http.get<InfoDocument[]>(`${this.BaseUrlFiles}/kb`).toPromise();
  }

  uploadFile(name: string, data: Blob, reportProgress?: (number) => void): Promise<any> {
    const formData = new FormData();
    formData.append('file', data, name);
    return new Promise<any>((resolve) => {
      this.http.post<any>(`${this.BaseUrlFiles}`, formData, { reportProgress: true, observe: 'events' }).subscribe(response => {
        if (response.type === HttpEventType.Response) {
          resolve(response.body);
        }
        if (response.type === HttpEventType.UploadProgress && reportProgress) {
          reportProgress(response.loaded / response.total);
        }
      });
    });
    // this.http.post<InfoDocument>(`${this.BaseUrl}`, formData).toPromise();
    //, { reportProgress: true, observe: 'events' }
  }

  updateFile(id: string, name: string, data: Blob, reportProgress?: (number) => void): Promise<any> {
    const formData = new FormData();
    formData.append('file', data, name);
    return new Promise<any>((resolve) => {
      this.http.put<any>(`${this.BaseUrlFiles}/${id}`, formData, { reportProgress: true, observe: 'events' }).subscribe(response => {
        if (response.type === HttpEventType.Response) {
          resolve(response.body);
        }
        if (response.type === HttpEventType.UploadProgress && reportProgress) {
          reportProgress(response.loaded / response.total);
        }
      });
    });
    // this.http.post<InfoDocument>(`${this.BaseUrl}`, formData).toPromise();
    //, { reportProgress: true, observe: 'events' }
  }

  filterFiles(filter: string, type: string): Promise<DocumentObject[]> {
    return this.http.get<DocumentObject[]>(`${this.BaseUrlFiles}/type/${type}?filter=${filter}`).toPromise();
  }
}

@Injectable({
  providedIn: 'root'
})
export class UnassignedFileService extends ServiceBase<DocumentObject> implements DataService<DocumentObject> {

  constructor(protected http: HttpClient, private accountService: AccountService, private fileService: FileService) {
    super();
  }

  get urlAction() {
    return `/reporting/${this.accountService.getCurrentUser().customer.id}/files/unassigned`;
  }

  getForBranch(branchId: string, urlPostfix?: string): Promise<DocumentObject[]> {
    return new Promise<DocumentObject[]>(() => { return null; })
  }
}

export class DocumentObject implements ServiceClass {
  id: string;
  originalName: string;
}