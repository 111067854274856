import { AppBranch, AppCustomer } from './../models/app';
import { RegisterRequest, TemporaryUser } from '../models/user';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { AppUser } from '../models/app';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject = new BehaviorSubject<AppUser>(null);
    public user: Observable<AppUser>;

    private branchSubject = new BehaviorSubject<AppBranch>(null);
    public branch: Observable<AppBranch>;

    refreshTokenTimeout: any;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.branch = this.branchSubject.asObservable();
        this.userSubject.subscribe(newUser => {
            if (this.refreshTokenTimeout) {
                clearTimeout(this.refreshTokenTimeout);
            }
            if (newUser && newUser.expiration) {
                const refreshAt = new Date(newUser.expiration.getTime() - 120 * 1000)
                const now = new Date();
                const delay = refreshAt.getTime() - now.getTime();
                this.refreshTokenTimeout = setTimeout(() => {
                    this.refreshToken();
                }, delay);
            }
            if (newUser && newUser.customer) {
                const storedBranch = JSON.parse(localStorage.getItem('branch'));
                const currentBranch = this.branchSubject.getValue();
                const userBranch = newUser.customer.branches.find(x => x.id === storedBranch?.id);
                if (!currentBranch && storedBranch?.id && userBranch) {
                    const branch = userBranch;
                    localStorage.setItem('branch', JSON.stringify(branch));
                    this.branchSubject.next(branch);
                } else if (!currentBranch || !newUser.customer.branches.find(x => x.id === currentBranch?.id)) {
                    const branch = newUser.customer.branches[0];
                    localStorage.setItem('branch', JSON.stringify(branch));
                    this.branchSubject.next(branch);
                }
            }
        });
        const storedUser = JSON.parse(localStorage.getItem('user'));
        let user: AppUser;
        if (storedUser) {
            user = new AppUser(storedUser);
            const now = new Date();
            if (user.expiration <= now) {
                user = undefined;
            } else {
                user.expiration = now;
            }
        }
        this.userSubject.next(user);
        this.user = this.userSubject.asObservable();
    }

    public get userValue(): AppUser {
        return this.userSubject.value;
    }

    switchBranch(branchId: string) {
        const customer = this.userSubject.getValue().customer;
        if (customer) {
            const branch = customer.branches.find(x => x.id === branchId);
            if (branch) {
                localStorage.setItem('branch', JSON.stringify(branch));
                this.branchSubject.next(branch);
            }
        }
    }

    switchCustomer(customer: AppCustomer) {
        const currentUser = this.userSubject.value;
        currentUser.customer = customer;
        localStorage.setItem('user', JSON.stringify(currentUser));
        this.userSubject.next(currentUser);
    }

    login(username: string, password: string) {
        return this.http.post<AppUser>(`${environment.apiUrl}/authenticate/login`, { username, password })
            .pipe(map(x => {
                const user = new AppUser(x);
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                this.userSubject.next(user);
                return user;
            }));
    }

    refreshToken() {
        const currentCustomer = this.userValue.customer;
        this.http.get<AppUser>(`${environment.apiUrl}/authenticate/renew`).subscribe(x => {
            const user = new AppUser(x);
            user.customer = currentCustomer;
            localStorage.setItem('user', JSON.stringify(user));
            this.userSubject.next(user);
        });
    }

    logout(navigate: boolean = true) {
        if (this.getCurrentUser()) {
            this.http.get<AppUser>(`${environment.apiUrl}/authenticate/logout`).subscribe(x => {
                this.resetUser(navigate);
            }, error => {
                this.resetUser(navigate);
            });
        } else {
            this.resetUser(navigate);
        }
    }

    private resetUser(navigate: boolean = true) {
        // remove user from local storage and set current user to null
        localStorage.removeItem('user');
        this.userSubject.next(null);
        this.branchSubject.next(null);
        if (navigate) {
            this.router.navigate(['/account/login']);
        }
    }

    markNotificationsAsRead(): Promise<any> {
        return this.http.patch(`${environment.apiUrl}/authenticate/notifications/mark-as-read`, {}).toPromise();
    }

    registerLogin(userId: string, email: string) {
        return this.http.post<AppUser>(`${environment.apiUrl}/authenticate/register`, { username: userId, password: email })
            .pipe(map(x => {
                const user = new AppUser(x);
                this.userSubject.next(user);
                return user;
            }));
    }

    requestPassword(email: string) {
        return this.http.post(`${environment.apiUrl}/signup/password`, { email: email });
    }

    resetPassword(model: RegisterRequest) {
        return this.http.post(`${environment.apiUrl}/signup/password`, model);
    }

    register(registerRequest: RegisterRequest) {
        return this.http.post(`${environment.apiUrl}/register`, registerRequest);
    }

    signup(registerRequest: RegisterRequest) {
        return this.http.post(`${environment.apiUrl}/signup`, registerRequest);
    }

    getTemporaryUserInfo() {
        return this.http.get<TemporaryUser>(`${environment.apiUrl}/register`);
    }

    getSignupInfo(email: string, token: string) {
        return this.http.get<TemporaryUser>(`${environment.apiUrl}/signup/${token}?email=${email}`);
    }

    getCurrentUser(): AppUser {
        return this.userSubject.getValue();
    }

    getCurrentBranch(): AppBranch {
        return this.branchSubject.getValue();
    }
}