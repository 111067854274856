import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyValuePair } from 'src/app/_helpers/static-list-helper';

@Component({
  selector: 'app-generic-message-dialog',
  templateUrl: './generic-message-dialog.component.html',
  styleUrls: ['./generic-message-dialog.component.scss']
})
export class GenericMessageDialogComponent implements OnInit {
  public inputValue: string = "";
  public inputDate: Date = null;

  constructor(
    public dialogRef: MatDialogRef<GenericMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public config: GenericMessageDialogConfig
  ) {
    if (config.initialValue) {
      if (config.inputType === "date") {
        this.inputDate = config.initialValue as Date;
      } else {
        this.inputValue = config.initialValue as string;
      }
    }
  }

  ngOnInit(): void {
  }

  doAction(key: string) {
    var input: string | Date;
    if (this.config.inputType === "date") {
      input = this.inputDate;
    } else {
      input = this.inputValue;
    }
    this.dialogRef.close({ event: key, input: input });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'cancel', input: null });
  }

  public static open(dialog: MatDialog, config: GenericMessageDialogConfig): MatDialogRef<GenericMessageDialogComponent> {
    return dialog.open(GenericMessageDialogComponent, {
      width: config.width,
      data: config,
    });
  }
}

export class GenericMessageDialogConfig {
  constructor(
    public title: string,
    public text: string,
    public icon: string = "warning_amber",
    public buttons: KeyValuePair[] = [{ key: "ok", value: "OK" }],
    public width: string = "450px",
    public inputLabel: string = "",
    public inputType: string = "text",
    public initialValue: string | Date = null
  ) { }
}

export interface GenericMessageDialogResult {
  event: string;
  input: string | Date;
}
