<h1 mat-dialog-title><strong>Neuigkeiten</strong></h1>
<div mat-dialog-content>
  <div style="display: flex; flex-direction: column;">
    <ul class="list">
      <ng-container *ngFor="let item of model">
        <ol class="item">{{item.timestamp | date:'shortDate'}}</ol>
        <ul class="list">
          <ol class="text">{{item.text}}</ol>
        </ul>
      </ng-container>
    </ul>
  </div>
</div>
<div mat-dialog-actions style="display: flex; flex-direction: row; justify-content: flex-end;">
  <button mat-button (click)="doAction()">OK</button>
</div>
