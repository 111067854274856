import { LoadingInterceptor } from './_helpers/loading.interceptor';
import { ComponentsModule } from './components/components.module';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { MaterialModule } from './material/material.module';

import { SwitchBranchDialogComponent } from './switch-branch-dialog/switch-branch-dialog.component';
import { HintDialogComponent } from './components/rating/hint-dialog/hint-dialog.component';
import { SwitchCustomerDialogComponent } from './switch-customer-dialog/switch-customer-dialog.component'
import { MatNativeDateModule } from '@angular/material/core';
import { DefaultComponent } from './default/default.component'
import localeDe from '@angular/common/locales/de';
import { DatePipe, registerLocaleData } from '@angular/common';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { DownloadComponent } from './download/download.component';
import { RedirectorComponent } from './redirector/redirector.component';
import { ClickStopPropagationDirective } from './_helpers/click-stop-propagation.directive';

registerLocaleData(localeDe);

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        MatNativeDateModule,
        ComponentsModule,
    ],
    exports: [
    ],
    declarations: [
        AppComponent,
        SwitchBranchDialogComponent,
        HintDialogComponent,
        SwitchCustomerDialogComponent,
        DefaultComponent,
        SnackBarComponent,
        DownloadComponent,
        RedirectorComponent,
        ClickStopPropagationDirective,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'de-DE' },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        DatePipe,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }