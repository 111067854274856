import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FundingFact } from 'src/app/models/funding-fact';
import { Project } from 'src/app/models/project';
import { environment } from 'src/environments/environment';
import { AccountService } from '..';
import { DataServiceBase } from './data-service-base';

@Injectable({
  providedIn: 'root'
})
export class FundingFactService extends DataServiceBase<FundingFact> {

  public getWithCriteria(branchId?: string): Promise<FundingFact[]> {
    let url = this.BaseUrl;
    if (branchId) {
      url = `${environment.apiUrl}/data/customers/${this.customer.id}/branches/${branchId}/${this.dataType}`
    }
    return this.http.get<FundingFact[]>(`${url}?includeCriteria=true`).toPromise();
  }

  public getWithCriteriaAllBranches(branchId?: string): Promise<FundingFact[][]> {
    let url = this.BaseUrl;
    if (branchId) {
      url = `${environment.apiUrl}/data/customers/${this.customer.id}/branches/${branchId}/${this.dataType}`
    }
    return this.http.get<FundingFact[][]>(`${url}?includeCriteria=true&allFundingFacts=true`).toPromise();
  }

  protected dataType = 'funding-facts';

  constructor(protected http: HttpClient, private accountService: AccountService) {
    super();

    accountService.branch.subscribe(x => this.branch = x);
    accountService.user.subscribe(x => this.customer = x?.customer);
  }

  getById(id: string): Promise<FundingFact> {
    return this.http.get<FundingFact>(`${this.BaseUrl}/${id}`).toPromise();
  }

  getProjects(id: string): Promise<FundingFactProjects> {
    return this.http.get<FundingFactProjects>(`${this.BaseUrl}/${id}/projects`).toPromise();
  }

}

export interface FundingFactProjects {
    validationFormula: string;
    isValid: boolean;
    criteria: {[key: string]: Project[]};
}