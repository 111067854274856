import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private _loadingSubject = new BehaviorSubject<boolean>(false);
  public IsLoading = this._loadingSubject.asObservable();

  private _loadingMap: Map<string, boolean> = new Map<string, boolean>();

  constructor() { }

  public start(): string {
    const id = LoadingService.uuidv4();
    this._loadingMap.set(id, true);
    this._loadingSubject.next(true);
    return id;
  }

  public stop(id: string): void {
    if (this._loadingMap.has(id)) {
      this._loadingMap.delete(id);
    }
    if (this._loadingMap.size === 0) {
      this._loadingSubject.next(false);
    }
  }

  private static uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
