import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-hint-dialog',
  templateUrl: './hint-dialog.component.html',
  styleUrls: ['./hint-dialog.component.scss']
})
export class HintDialogComponent implements OnInit {

  hint: string;
  readonly: boolean;

  constructor(public dialogRef: MatDialogRef<HintDialogComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if (this.data) {
      this.hint = this.data.hint;
      this.readonly = this.data.readonly ?? false;
    }
  }

  ok() {
    if (this.readonly) {
      this.dialogRef.close();
    } else if (this.hint) {
      this.dialogRef.close({ data: this.hint });
    } else {
      this.dialogRef.close({ data: false });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
