import { Stakeholder } from 'src/app/models/stakeholder';
import { ServiceClass } from './../_services/service-base';
import { Branch } from './branch';
export class Task implements ServiceClass {
    public id: string;
    public description: string;
    public note: string;
    public priority: string;
    public assignee: string;
    public dueDate: Date;
    public doneDate: Date;
    public createdDate: Date;
    public assigneeStakeholderId: string;
    public assigneeStakeholder?: Stakeholder;
    public branch?: Branch;
}

export class TaskConstants {
    public static readonly Priorities = {
        'normal': 'normal',
        'high': 'wichtig',
        'done': 'erledigt',
    }
}
