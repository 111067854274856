import { TaskOverviewComponent } from './home/task-overview/task-overview.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './_helpers';
import { DownloadComponent } from './download/download.component';
import { RedirectorComponent } from './redirector/redirector.component';

const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const administrationModule = () => import('./administration/administration.module').then(x => x.AdministrationModule);
const homeModule = () => import('./home/home.module').then(x => x.HomeModule);
const reportingModule = () => import('./reporting/reporting.module').then(x => x.ReportingModule);

export let routes: Routes = [
    //{ path: '', loadChildren: homeModule, canActivate: [AuthGuard] },
    { path: '', redirectTo: 'redirector', pathMatch: 'full' },
    { path: 'redirector', component: RedirectorComponent, canActivate: [AuthGuard] },
    { path: 'assessment', loadChildren: homeModule, canActivate: [AuthGuard] },
    { path: 'reporting', loadChildren: reportingModule, canActivate: [AuthGuard] },
    { path: 'account', loadChildren: accountModule },
    { path: 'administration', loadChildren: administrationModule, canActivate: [AuthGuard] },
    { path: 'all-tasks', component: TaskOverviewComponent, canActivate: [AuthGuard] },
    {
        path: 'assets', children: [
            { path: '**', component: DownloadComponent, canActivate: [AuthGuard] },
        ],
    },
    // otherwise redirect to home
    { path: '**', redirectTo: '' },
    //{ path: '**', component: DebugComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }