<h1 mat-dialog-title><strong>Standort wechseln</strong></h1>
<form>
  <div mat-dialog-content>
    <div style="display: flex; flex-direction: column;">
      <mat-form-field appearance="fill">
        <input placeholder="Standort" matInput [(ngModel)]="selectedValue" [matAutocomplete]="auto"
          (ngModelChange)="filterValues();" name="branch"/>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let option of filteredValues" [value]="option">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions style="display: flex; flex-direction: row; justify-content: flex-end;">
    <button type="submit" mat-button (click)="doAction()" [disabled]="!IsValueSelected">OK</button>
    <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Abbrechen</button>
  </div>
</form>
