<ng-container *ngIf="!isDisabled">
  <a mat-list-item [ngStyle]="{'padding-left': (depth * 12) + 'px'}" (click)="onItemSelected(item)"
    [ngClass]="{'active': isActive, 'expanded': expanded}" class="menu-list-item menu-level-{{depth}}"
    (mouseleave)="tooltip?.hide()" #tooltip="matTooltip" [matTooltip]="iconOnly ? item.displayName : ''">
    <mat-icon class="routeIcon" [style]="item.iconStyle">{{item.iconName}}</mat-icon>
    <span class="display-name" *ngIf="!iconOnly">{{item.displayName}}</span>
    <span fxFlex *ngIf="item.children && item.children.length">
      <span fxFlex></span>
      <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
        expand_more
      </mat-icon>
    </span>
  </a>
  <div *ngIf="expanded">
    <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1" [baseRoute]="baseRoute">
    </app-menu-list-item>
  </div>
</ng-container>