<h1 mat-dialog-title><strong>Notiz</strong></h1>
<div mat-dialog-content>
  <div style="display: flex; flex-direction: column;">
    <mat-form-field appearance="fill">
      <textarea placeholder="Notiz" matInput [(ngModel)]="hint" rows="10" [readonly]="readonly"></textarea>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions style="display: flex; flex-direction: row; justify-content: flex-end;">
  <button mat-button (click)="ok()">OK</button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Abbrechen</button>
</div>
