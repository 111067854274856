import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-banner',
  templateUrl: './generic-banner.component.html',
  styleUrls: ['./generic-banner.component.scss']
})
export class GenericBannerComponent implements OnInit {
  @Input() private color: string = "#0000ff";

  constructor() { }

  public get backgroundColor(): string {
    return this.increaseBrightness(this.color, 85);
  }

  public get borderColor(): string {
    return this.color;
  }

  ngOnInit(): void {
  }

  private increaseBrightness(hex: string, percent: number): string {
    // strip the leading # if it's there
    hex = hex.replace(/^\s*#|\s*$/g, '');

    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if (hex.length == 3) {
      hex = hex.replace(/(.)/g, '$1$1');
    }

    var r = parseInt(hex.substr(0, 2), 16),
      g = parseInt(hex.substr(2, 2), 16),
      b = parseInt(hex.substr(4, 2), 16);

    return '#' +
      ((0 | (1 << 8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
      ((0 | (1 << 8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
      ((0 | (1 << 8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
  }
}
