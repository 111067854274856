import { ProjectRelation } from './../../models/project-relation';
import { ProjectBranch } from './../../models/project-branch';
import { ProjectFunding } from './../../models/project-funding';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Project } from 'src/app/models/project';
import { AccountService } from '..';
import { DataServiceBase } from './data-service-base';
import { CostLine } from 'src/app/models/cost-line';
import { environment } from 'src/environments/environment';
import { ProjectCoverage } from 'src/app/models/project-coverage';
import { Offer } from 'src/app/models/offer';
import { ProjectStatus } from 'src/app/models/project-status';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends DataServiceBase<Project> {
  protected dataType = 'projects';

  constructor(protected http: HttpClient, private accountService: AccountService) {
    super();

    accountService.branch.subscribe(x => this.branch = x);
    accountService.user.subscribe(x => this.customer = x?.customer);
  }

  private getBaseUrl(branchId?: string) {
    return `${environment.apiUrl}${this.getUrlAction(branchId)}`;
  }

  public addCopy(id: string, obj: Project): Promise<Project> {
    const promise = this.http.post<Project>(`${this.BaseUrl}/${id}/copy`, obj).toPromise();
    promise.then(x => {
      if (this.addCallback) {
        this.addCallback(x);
      }
    })
    return promise;
  }

  getWithCosts(): Promise<ProjectDetail[]> {
    return this.http.get<ProjectDetail[]>(`${this.BaseUrl}/?projectType=&includeCostInfo=true`).toPromise();
  }

  getByType(projectType: string): Promise<Project[]> {
    return this.http.get<Project[]>(`${this.BaseUrl}/?projectType=${projectType}`).toPromise();
  }

  getById(id: string): Promise<Project> {
    return this.http.get<Project>(`${this.BaseUrl}/${id}`).toPromise();
  }

  getStakeholders(projectId: string, branchId?: string): Promise<any[]> {
    return this.http.get<any[]>(`${this.getBaseUrl(branchId)}/${projectId}/stakeholders`).toPromise();
  }

  addStakeholder(projectId: string, entity: any): Promise<any> {
    return this.http.post<any>(`${this.BaseUrl}/${projectId}/stakeholders`, entity).toPromise();
  }

  updateStakeholder(projectId: string, entity: any): Promise<any> {
    return this.http.put<any>(`${this.BaseUrl}/${projectId}/stakeholders/${entity.id}`, entity).toPromise();
  }

  deleteStakeholder(projectId: string, id: string): Promise<Object> {
    return this.http.delete<Object>(`${this.BaseUrl}/${projectId}/stakeholders/${id}`).toPromise();
  }

  getCostLines(projectId: string, branchId?: string): Promise<CostLine[]> {
    return this.http.get<any[]>(`${this.getBaseUrl(branchId)}/${projectId}/costs`).toPromise();
  }

  addCostLine(projectId: string, entity: any): Promise<any> {
    return this.http.post<any>(`${this.BaseUrl}/${projectId}/costs`, entity).toPromise();
  }

  updateCostLine(projectId: string, entity: any): Promise<any> {
    return this.http.put<any>(`${this.BaseUrl}/${projectId}/costs/${entity.id}`, entity).toPromise();
  }

  deleteCostLine(projectId: string, id: string): Promise<Object> {
    return this.http.delete<Object>(`${this.BaseUrl}/${projectId}/costs/${id}`).toPromise();
  }

  getOffers(projectId: string, branchId?: string): Promise<Offer[]> {
    return this.http.get<any[]>(`${this.getBaseUrl(branchId)}/${projectId}/offers`).toPromise();
  }

  addOffer(projectId: string, entity: Offer): Promise<Offer> {
    return this.http.post<any>(`${this.BaseUrl}/${projectId}/offers`, entity).toPromise();
  }

  updateOffer(projectId: string, entity: Offer): Promise<Offer> {
    return this.http.put<any>(`${this.BaseUrl}/${projectId}/offers/${entity.id}`, entity).toPromise();
  }

  deleteOffer(projectId: string, id: string): Promise<Object> {
    return this.http.delete<Object>(`${this.BaseUrl}/${projectId}/offers/${id}`).toPromise();
  }

  getFundings(projectId: string, branchId?: string): Promise<ProjectFunding[]> {
    return this.http.get<any[]>(`${this.getBaseUrl(branchId)}/${projectId}/fundings`).toPromise();
  }

  addFunding(projectId: string, entity: any): Promise<any> {
    return this.http.post<any>(`${this.BaseUrl}/${projectId}/fundings`, entity).toPromise();
  }

  updateFunding(projectId: string, entity: any): Promise<any> {
    return this.http.put<any>(`${this.BaseUrl}/${projectId}/fundings/${entity.id}`, entity).toPromise();
  }

  deleteFunding(projectId: string, id: string): Promise<Object> {
    return this.http.delete<Object>(`${this.BaseUrl}/${projectId}/fundings/${id}`).toPromise();
  }

  getRelations(projectId: string, branchId?: string): Promise<ProjectRelation[]> {
    return this.http.get<any[]>(`${this.getBaseUrl(branchId)}/${projectId}/relations`).toPromise();
  }

  addRelation(projectId: string, entity: ProjectRelation): Promise<ProjectRelation> {
    return this.http.post<ProjectRelation>(`${this.BaseUrl}/${projectId}/relations`, entity).toPromise();
  }

  updateRelation(projectId: string, entity: ProjectRelation): Promise<ProjectRelation> {
    return this.http.put<ProjectRelation>(`${this.BaseUrl}/${projectId}/relations/${entity.id}`, entity).toPromise();
  }

  deleteRelation(projectId: string, id: string): Promise<Object> {
    return this.http.delete<Object>(`${this.BaseUrl}/${projectId}/relations/${id}`).toPromise();
  }

  getBranches(projectId: string, branchId?: string): Promise<ProjectBranch[]> {
    return this.http.get<any[]>(`${this.getBaseUrl(branchId)}/${projectId}/branches`).toPromise();
  }

  updateBranches(projectId: string, model: ProjectBranch[]): Promise<ProjectBranch[]> {
    return this.http.put<any[]>(`${this.BaseUrl}/${projectId}/branches`, model).toPromise();
  }

  getCoverage(projectId: string, branchId?: string): Promise<ProjectCoverage[]> {
    return this.http.get<any[]>(`${this.getBaseUrl(branchId)}/${projectId}/coverage`).toPromise();
  }

  updateCoverage(projectId: string, model: ProjectCoverage[]) {
    return this.http.put<any[]>(`${this.BaseUrl}/${projectId}/coverage`, model).toPromise();
  }

  getStatus(projectId: string): Promise<ProjectStatus[]> {
    return this.http.get<any>(`${this.BaseUrl}/${projectId}/status`).toPromise();
  }

  updateStatus(projectId: string, model: ProjectStatus) {
    return this.http.put<any>(`${this.BaseUrl}/${projectId}/status/${model.branchId}`, model).toPromise();
  }
}


export interface ProjectDetail {
  project: Project;
  costs: CostLine[];
  branches: ProjectBranch[];
  fundings: ProjectFunding[];
  coverage: ProjectCoverage[];
}
