<h1 mat-dialog-title><strong>{{config.title}}</strong></h1>
<div mat-dialog-content class="content">
    <div class="message">
        <div>
            <mat-icon>{{config.icon}}</mat-icon>
        </div>
        <div>
            <p>{{config.text}}</p>
        </div>
    </div>
    <div *ngIf="config.inputLabel?.length" class="input">
        <mat-form-field appearance="fill" *ngIf="config.inputType==='text'">
            <mat-label>{{config.inputLabel}}</mat-label>
            <textarea matInput [(ngModel)]="inputValue" name="text" rows="20"></textarea>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="config.inputType==='date'">
            <mat-label>{{config.inputLabel}}</mat-label>
            <input matInput [matDatepicker]="dateValue" [(ngModel)]="inputDate">
            <mat-datepicker-toggle matSuffix [for]="dateValue"></mat-datepicker-toggle>
            <mat-datepicker #dateValue></mat-datepicker>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions style="display: flex; flex-direction: row; justify-content: flex-end;">
    <button *ngFor="let button of config.buttons" type="submit" mat-button
        (click)="doAction(button.key)">{{button.value}}</button>
</div>