import { Subscription } from 'rxjs';
export abstract class SubscriptionHelper {
    private _subscriptions: Subscription[] = [];

    protected addSubscription(subscription: Subscription): void {
        this._subscriptions.push(subscription);
    }

    protected unsubscribeAll(): void {
        this._subscriptions.forEach(x => x.unsubscribe());
    }
}
