import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { BehaviorSubject, interval } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { FileService } from 'src/app/_services/reporting/file.service';
import { PreviewService } from 'src/app/reporting/documents/inbox/inbox.component';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit, AfterViewInit {
  private _documentId = new BehaviorSubject<string>('');

  protected src: Blob = null;
  protected mimeType = "";
  protected fileName = "";

  @ViewChild(PdfJsViewerComponent) private pdfComponent!: PdfJsViewerComponent;
  @ViewChild('pdfViewer') private pdfViewer;
  @ViewChild('imageViewer') private imageViewer;

  @Input() public set documentId(value: string) {
    this._documentId.next(value);
  }

  @Output() public viewerChange = new EventEmitter<PdfJsViewerComponent>();
  @Output() public onDocumentLoad = new EventEmitter<any>();

  constructor(private fileService: FileService, private previewService: PreviewService) {
    this._documentId.pipe(debounce(() => interval(500))).subscribe(id => {
      if (id) {
        this.fileService.getById(id).then(doc => {
          this.mimeType = doc.mimeType;
          this.fileName = doc.originalName;
          this.fileService.downloadFile('', id).then(
            (res) => {
              this.src = res;
              this.refresh();
            }
          );
        });
      } else {
        this.src = null;
        this.mimeType = "";
        this.fileName = "";
        this.refresh();
      }
    });
  }

  private refresh() {
    if (this.pdfViewer && this.getType() === "pdf") {
      this.pdfViewer.pdfSrc = this.src;
      this.pdfViewer.refresh();
    } else if (this.imageViewer && this.getType() === "image") {
      const reader = new FileReader();
      reader.readAsDataURL(this.src);
      reader.onload = _ => {
        const url = reader.result;
        this.imageViewer.nativeElement.src = url;
      }
    }
  }

  protected getType(): string {
    if (this.mimeType === "application/pdf") {
      return "pdf";
    } else if (this.mimeType?.startsWith("image/")) {
      return "image";
    } else if (this.src) {
      return "other";
    }
    return "";
  }

  protected download() {
    fileSaver.saveAs(this.src, this.fileName);
  }

  ngAfterViewInit(): void {
    this.viewerChange.emit(this.pdfComponent);
  }

  ngOnInit(): void {
  }

  public pagesLoadedEvent(event: any): void {
    this.pdfComponent.PDFViewerApplication.appConfig.viewerContainer.onmouseup = (event) => {
      var content = event.view.getSelection().toString();
      this.previewService.setText(content);
    }
  }
}
