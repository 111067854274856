import { Pipe, PipeTransform } from "@angular/core";

export interface KeyValuePair {
    key: string;
    value: string;
}

export class StaticListHelper {
    public static ToList(object: any): KeyValuePair[] {
        return Object.keys(object).map(key => ({ key, value: object[key] })).filter(x => !x.value.toString().startsWith(' '));
    }
}

@Pipe({ name: 'translateKey' })
export class ListTranslatePipe implements PipeTransform {
    transform(value: string, list?: { [key: string]: string }): string {
        return list ? list[value?.replace('.', '_')] : value;
    }
}
