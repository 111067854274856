import { LockService } from '../_services/lock-service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

  constructor(private webSocketService: LockService) { }

  ngOnInit(): void {
  }
  ws() {
    //this.webSocketService.sendMessage('test');
  }
}
