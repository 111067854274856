import { ServiceClass } from "../_services/service-base";

export class CostLine implements ServiceClass {
    public id: string;
    public title: string;
    public type: string;
    public interval: string;
    public valueTotal: number;
    public valueSecurity: number;
    public year: string;
    public status: string;
    public month: number;
    public runtime?: number;
    public costsByYear?: { [key: number]: number };
    public securityCostsByYear?: { [key: number]: number };
    public khzgCostsByYear?: { [key: number]: number };
    public khzgSecurityCostsByYear?: { [key: number]: number };
    public isSecurity: boolean;
    public securityDescription: string;
}

export class CostLineConstants {
    public static readonly Types = {
        'software-license': 'Lizenzen',
        'service': 'Wartung & Service',
        'software-service': 'Dienstleistung Industrie',
        'consulting': 'Dienstleistung Beratung',
        'personal-service': 'Dienstleistung ext. Personal',
        'training-service': 'Dienstleistung Schulung',
        'staff': 'Personal (intern)',
        'existing-staff': 'Bestehendes Personal (intern)',
        'documents': 'Nachweise nach §25',
        'hardware': 'Hardware',
        'construction': 'Baukosten',
        'aas': ' As-a-Service Kosten',
        'technical': 'Weitere technische Maßnahmen'
    };
    public static readonly Status = {
        'estimate': 'Schätzung',
        'offered': 'Angebot liegt vor',
        'committed': 'Beauftragt',
    };
    public static readonly Intervals = {
        'once': 'Einmalig',
        'yearly': 'Jährlich',
        'monthly': 'Monatlich',
    };
    public static readonly TypeMappings = {
        'software-license': 'action',
        'service': 'action',
        'software-service': 'action',
        'consulting': 'consulting',
        'personal-service': 'action',
        'training-service': 'staff',
        'staff': 'staff',
        'existing-staff': 'staff',
        'hardware': 'action',
        'construction': 'construction',
        'aas': 'action',
        'technical': 'action',
        'documents': 'documents',
    };
    public static readonly ApplicationTypes = {
        'action': 'Kosten für erforderliche technische und informationstechnische Maßnahmen',
        'consulting': 'Kosten für Beratungsleistungen bei der Planung des konkreten Vorhabens',
        'staff': 'Kosten für erforderliche personelle Maßnahmen einschließlich der Kosten für die Schulungen des Personals',
        'construction': 'Kosten für räumliche Maßnahmen',
        'documents': 'Kosten für die Beschaffung von Nachweisen nach §25 Abs 1 Sa. 1 Nr2',
        'other': 'Sonstige Kosten',
    };
}
