import { FundingFactDefinition, CriteriumDefinition } from './../models/definitions';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceBase } from './service-base';

@Injectable({
  providedIn: 'root'
})
export class FundingFactDefinitionsService extends ServiceBase<FundingFactDefinition>{

  public getAll(): Promise<FundingFactDefinition[]> {
    return this.http.get<FundingFactDefinition[]>(`${this.BaseUrl}?includeAll=true`).toPromise();
  }

  public getWithCriteria(includeSub: boolean = true): Promise<FundingFactDefinition[]> {
    return this.http.get<FundingFactDefinition[]>(`${this.BaseUrl}?includeCriteria=true&noSubDefinitions=${!includeSub}`).toPromise();
  }

  urlAction = '/definitions/funding-facts';
  constructor(protected http: HttpClient) {
    super();
  }

  getCriteria(id: string): Promise<CriteriumDefinition[]> {
    return this.http.get<CriteriumDefinition[]>(`${this.BaseUrl}/${id}/criteria`).toPromise();
  }
}
