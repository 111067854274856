import { RouterParams } from './../../_helpers/router-params';
import { StakeholderService } from 'src/app/_services/data/stakeholder.service';
import { ProjectService } from './project.service';
import { DataService } from './data-service-base';
import { Injectable } from '@angular/core';
import { Stakeholder } from 'src/app/models/stakeholder';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProjectStakeholderService implements DataService<ProjectStakeholder> {
  private stakeholder: Stakeholder[];

  constructor(private _projectService: ProjectService, private _stakeholderService: StakeholderService, private routerParams: RouterParams) {
    this._stakeholderService.stakeholder.subscribe(x => this.stakeholder = x);
  }

  getForBranch(branchId: string): Promise<ProjectStakeholder[]> {
    return new Promise((resolve) => {
      this.routerParams.params.pipe(first()).subscribe(params => {
        const projectId = params['projectId'];
        if (projectId) {
          this._projectService.getStakeholders(projectId).then(result => {
            resolve(result.map(x => this.toModel(x)));
          });
        }
      });
    });
  }

  get(): Promise<ProjectStakeholder[]> {
    return new Promise((resolve) => {
      this.routerParams.params.pipe(first()).subscribe(params => {
        const projectId = params['projectId'];
        if (projectId) {
          this._projectService.getStakeholders(projectId).then(result => {
            resolve(result.map(x => this.toModel(x)));
          });
        }
      });
    });
  }

  add(model: ProjectStakeholder): Promise<ProjectStakeholder> {
    return new Promise((resolve) => {
      this.routerParams.params.pipe(first()).subscribe(params => {
        const projectId = params['projectId'];
        if (projectId) {
          this._projectService.addStakeholder(projectId, this.toEntity(model)).then(result => {
            resolve(this.toModel(result));
          });
        }
      });
    });
  }

  update(model: ProjectStakeholder): Promise<ProjectStakeholder> {
    return new Promise((resolve) => {
      this.routerParams.params.pipe(first()).subscribe(params => {
        const projectId = params['projectId'];
        if (projectId) {
          this._projectService.updateStakeholder(projectId, this.toEntity(model)).then(result => {
            resolve(this.toModel(result));
          });
        }
      });
    });
  }

  delete(id: string): Promise<Object> {
    return new Promise((resolve) => {
      this.routerParams.params.pipe(first()).subscribe(params => {
        const projectId = params['projectId'];
        if (projectId) {
          this._projectService.deleteStakeholder(projectId, id).then(result => {
            resolve(result);
          });
        }
      });
    });
  }

  private getStakeholder(id: string): Stakeholder {
    return this.stakeholder.find(x => x.id === id);
  }

  private toEntity(model: ProjectStakeholder) {
    return {
      id: model.id,
      role: model.role,
      stakeholderId: model.person.id,
      specialRole: model.specialRole,
    }
  }

  private toModel(entity: any): ProjectStakeholder {
    return {
      id: entity.id,
      role: entity.role,
      person: this.getStakeholder(entity.stakeholderId),
      specialRole: entity.specialRole,
    }
  }
}


export interface ProjectStakeholder {
  id: string;
  person: Stakeholder;
  role: string;
  stakeholderId?: string;
  specialRole: string;
}