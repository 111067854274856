export class LockedEntity {
    constructor(other: LockedEntity) {
        if (other) {
            this.lockId = other.lockId;
            this.branchId = other.branchId;
            this.entityId = other.entityId;
            this.entityType = other.entityType;
            if (other.expiration) {
                this.expiration = new Date(other.expiration);
            }
            this.user = other.user;
        }
    }

    public lockId?: string;
    public branchId: string;
    public entityId: string;
    public entityType: string;
    public expiration?: Date;
    public user?: string;
}
