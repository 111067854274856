import { Branch } from './../../models/branch';
import { AppBranch, AppCustomer } from 'src/app/models/app';
import { environment } from 'src/environments/environment';
import { ServiceBase, ServiceClass } from '../service-base';

export abstract class DataServiceBase<T extends ServiceClass> extends ServiceBase<T> implements DataService<T> {
  protected abstract dataType: string;

  protected branch: AppBranch;

  protected customer: AppCustomer;

  protected get urlAction(): string {
    return this.getUrlAction();
  }

  protected getUrlAction(branchId?: string, customerId?: string) {
    return `/data/customers/${customerId ?? this.customer.id}/branches/${branchId ?? this.branch.id}/${this.dataType}`;
  }

  public getForBranch(branchId: string, customerId?: string): Promise<T[]> {
    const url = `${environment.apiUrl}${this.getUrlAction(branchId, customerId)}`;
    return this.get(url);
  }

  public updateForBranch(branch: Branch, obj: T): Promise<T> {
    const url = `${environment.apiUrl}${this.getUrlAction(branch.id, branch.customer.id)}`;
    const promise = this.http.put<T>(`${url}/${obj.id}`, obj).toPromise();
    promise.then(x => {
      if (this.updateCallback) {
        this.updateCallback(x);
      }
    })
    return promise;
  }

  public deleteForBranch(branch: Branch, id: string): Promise<Object> {
    const url = `${environment.apiUrl}${this.getUrlAction(branch.id, branch.customer.id)}`;
    const promise = this.http.delete(`${url}/${id}`).toPromise();
    promise.then(x => {
      if (this.deleteCallback) {
        this.deleteCallback(id);
      }
    })
    return promise;
  }
  constructor() {
    super();
  }
}

export interface DataService<T extends ServiceClass> {
  get(urlPostfix?: string): Promise<T[]>;
  getForBranch(branchId: string, urlPostfix?: string): Promise<T[]>;
  add(entity: T, urlPostfix?: string): Promise<T>;
  update(entity: T, urlPostfix?: string): Promise<T>;
  //updateForBranch(branch: Branch, entity: T): Promise<T>;
  delete(id: string, urlPostfix?: string): Promise<Object>;
  //deleteForBranch(branch: Branch, id: string): Promise<Object>;
}