import { SaveDialogComponent } from './../components/save-dialog/save-dialog.component';
import { CanComponentDeactivate } from './deactivate.guard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LockedEntity } from './../models/locked-entity';
import { LockService } from './../_services/lock-service';
import { from, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionHelper } from './subscription-helper';

export abstract class LockAware extends SubscriptionHelper implements CanComponentDeactivate {
    private readonly _lockHandler: LockHandler;
    protected abstract reload(): void;
    protected matDialog: MatDialog;

    public get isChanged(): boolean {
        return false;
    }

    public get lockHandler(): LockHandler {
        return this._lockHandler;
    }

    public get currentLock(): LockedEntity {
        return this.lockHandler.currentLock;
    }

    public get isLockedForMe(): boolean {
        return this.lockHandler.isLockedForMe;
    }

    public get lockedBy(): string {
        return this.lockHandler.lockedBy;
    }

    protected savedModel: string;
    protected model;

    constructor(entityType: string, lockService: LockService, protected matSnackBar: MatSnackBar = undefined) {
        super();
        this._lockHandler = new LockHandler(
            entityType,
            lockService,
            (message: string) => {
                if (matSnackBar) {
                    matSnackBar.open(message, undefined, { duration: 5000 });
                }
            });
        lockService.lockRelease.subscribe(x => {
            if (this.lockHandler.entityId && x === this.lockHandler.entityId) {
                this.reload();
            }
        });
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.isChanged) {
            if (this.matDialog) {
                return from(new Promise<boolean>((resolve, reject) => {
                    const dialogRef = this.matDialog.open(SaveDialogComponent, {
                        width: '450px',
                        data: { "oldValue": this.savedModel, "newValue": JSON.stringify(this.model) },
                    });
                    dialogRef.afterClosed().subscribe(result => {
                        if (result?.event === 'Save') {
                            if (this._lockHandler?.save) {
                                this._lockHandler.save().then(
                                    success => resolve(true),
                                    failed => resolve(false)
                                );
                            }
                        } else if (result?.event === 'SaveAndRelease') {
                            this._lockHandler.save().then(
                                success => {
                                    this.lockHandler.releaseLock().then(
                                        success => resolve(true),
                                        failed => resolve(false)
                                    );
                                },
                                failed => resolve(false)
                            );
                        } else if (result?.event === 'Dismiss') {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    });
                }));
            } else {
                console.log("cannot deactivate. cannot open dialog.")
                return false;
            }
        }
        return true;
    }
}

export class LockHandler {
    private _entityId: string;
    public branchId: string = undefined;

    public save: () => Promise<any>;

    public get canSave(): boolean {
        return Boolean(this.save);
    }

    public get entityId(): string {
        return this._entityId;
    }

    public get canLock(): boolean {
        return this.lockService.canLock;
    }

    public set entityId(value: string) {
        this._currentLock = undefined;
        this._entityId = value;
        this.lockService.isLocked(this._entityId, this.entityType);
        this.checkForLock();
    }

    private _currentLock: LockedEntity = undefined;
    public get currentLock(): LockedEntity {
        return this._currentLock;
    }

    public get isLockedForMe(): boolean {
        return Boolean(this._currentLock?.lockId) && this._currentLock.entityId === this.entityId;
    }

    public get lockedBy(): string {
        return this._currentLock?.entityId === this.entityId ? this._currentLock?.user : '';
    }

    public get isLocked(): boolean {
        return this.isLockedForMe || (this.lockedBy !== '');
    }

    constructor(private entityType: string, private lockService: LockService, private openSnackbar: (message: string) => void) {
        this.checkForLock();
    }

    private checkForLock() {
        if (!this._entityId) {
            return;
        }

        this.lockService.locks.subscribe(locks => {
            if (!this.isLockedForMe) {
                const lock = locks.find(x => x.entityType === this.entityType && x.entityId === this._entityId && x.lockId);
                this._currentLock = lock ?? locks.find(x => x.entityType === this.entityType && x.entityId === this._entityId);
            }
        });
    }

    public tryGetLock(callback?: () => void, fallback?: () => void, entityId?: string, entityType?: string) {
        if (!this.lockService.canLock) {
            if (fallback) {
                fallback();
            }
            return;
        }
        if (!entityId && this.isLockedForMe) {
            if (callback) {
                callback();
            }
            return;
        }
        this.requestLock(false, entityId, entityType).then(successfull => {
            if (successfull) {
                if (callback) {
                    callback();
                }
            } else {
                this.openSnackbar(`Bearbeitung nicht möglich. Gesperrt durch ${this.lockedBy}.`);
                if (fallback) {
                    fallback();
                }
            }
        });
    }

    public requestLock(isRetry: boolean = false, entityId?: string, entityType?: string): Promise<boolean> {
        return new Promise(resolve => {
            this.lockService.getLock(entityId ?? this._entityId, entityType ?? this.entityType, this.branchId).then(x => {
                if (!x && !isRetry) {
                    setTimeout(() => {
                        this.requestLock(true, entityId, entityType);
                    }, 500);
                } else {
                    this._currentLock = x;
                    resolve(Boolean(x?.lockId));
                }
            });
        })
    }

    public releaseLock(): Promise<any> {
        if (this._currentLock?.lockId) {
            return this.lockService.releaseLock(this._currentLock.lockId).then(() => this._currentLock = undefined);
        } else {
            return new Promise(resolve => resolve(true));
        }
    }
}