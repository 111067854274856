import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationModel } from 'src/app/models/notification';
import { TableDialogData } from 'src/app/models/table-dialog-data';
import { AccountService } from 'src/app/_services';

@Component({
  selector: 'app-notifications-dialog',
  templateUrl: './notifications-dialog.component.html',
  styleUrls: ['./notifications-dialog.component.scss']
})
export class NotificationsDialogComponent implements OnInit {
  public model: NotificationModel[];

  constructor(
    public dialogRef: MatDialogRef<NotificationsDialogComponent>,
    private _accountService: AccountService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: TableDialogData<NotificationModel[]>
  ) {
    this.model = JSON.parse(JSON.stringify(this.data));
  }

  ngOnInit(): void {
  }

  doAction() {
    this._accountService.markNotificationsAsRead().then(() => {
      this.dialogRef.close({ event: 'MarkRead' });
    })
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}
