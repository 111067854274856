<h1 mat-dialog-title><strong>Projekt Export</strong></h1>
<div mat-dialog-content>
  <div>
    <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
    <span>{{statusMsg}}</span>
  </div>
</div>
<div mat-dialog-actions style="display: flex; flex-direction: row; justify-content: flex-end;">
  <button type="submit" mat-button (click)="closeDialog()">OK</button>
</div>
