<mat-drawer-container class="sidenav-container" autosize>
    <mat-drawer class="sidenav" mode="side" opened #sidenav>
        <div class="button" (click)="toggleNav()" (mouseleave)="tooltip?.hide()" #tooltip="matTooltip" [matTooltip]="!collapsed ? 'Menü einklappen' : ''">
            <mat-icon *ngIf="!collapsed">chevron_left</mat-icon>
            <span class="nav-title" *ngIf="!collapsed && navTitle">{{navTitle}}</span>
            <mat-icon *ngIf="collapsed" matTooltip="Menü ausklappen">chevron_right</mat-icon>
            <mat-icon *ngIf="collapsed && icon" class="position" [matTooltip]="navTitle">{{icon}}</mat-icon>
        </div>
        <mat-nav-list>
            <app-menu-list-item *ngFor="let item of navItems" [item]="item" [baseRoute]="baseRoute"
                [iconOnly]="collapsed"></app-menu-list-item>
        </mat-nav-list>
    </mat-drawer>
    <div class="sidenav-content">
        <ng-content></ng-content>
    </div>
</mat-drawer-container>