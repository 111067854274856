<div *ngIf="lockHandler.lockedBy" class="banner">
  <ng-container *ngIf="lockHandler.isLockedForMe">
    <span>Zur Bearbeitung gesperrt</span>
    <button mat-button (click)="lockHandler.releaseLock()" mat-flat-button color="primary" class="small">
      <mat-icon>lock_open</mat-icon> Sperre aufheben
    </button>
    <button mat-button (click)="lockHandler.save()" [disabled]="isSaveDisabled" *ngIf="isSaveVisible" mat-flat-button color="accent" class="small">
      <mat-icon>save</mat-icon> Speichern
    </button>
  </ng-container>
  <ng-container *ngIf="!lockHandler.isLockedForMe && lockHandler.lockedBy">
    <span>Gesperrt durch {{lockHandler.lockedBy}}</span>
  </ng-container>
</div>
