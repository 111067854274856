import { Note } from './../../models/note';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountService } from '..';
import { DataServiceBase } from './data-service-base';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NoteService extends DataServiceBase<Note> {
  protected dataType = 'notes';
  private newNoteSubject = new BehaviorSubject<Note>(null);
  public newNote: Observable<Note>;

  constructor(protected http: HttpClient, private accountService: AccountService) {
    super();

    accountService.branch.subscribe(x => this.branch = x);
    accountService.user.subscribe(x => this.customer = x?.customer);

    this.newNote = this.newNoteSubject.asObservable();
  }

  public add(obj: Note): Promise<Note> {
    return this.http.post<Note>(`${this.BaseUrl}`, obj).toPromise().then(x => {
      this.newNoteSubject.next(x);
      return x;
    });
  }
}
