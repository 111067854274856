import { ProjectCoverage } from './../../../models/project-coverage';
import { ProjectFundingService } from './../../../_services/data/project-funding.service';
import { ProjectCostService } from 'src/app/_services/data/project-cost.service';
import { ProjectService, ProjectDetail } from './../../../_services/data/project.service';
import { ProjectBranch } from './../../../models/project-branch';
import { ProjectFunding } from './../../../models/project-funding';
import { ProjectStakeholder, ProjectStakeholderService } from 'src/app/_services/data/project-stakeholder.service';
import { CostLine, CostLineConstants } from './../../../models/cost-line';
import { FundingFactDefinition } from './../../../models/definitions';
import { FundingFact, FundingFactConstants } from './../../../models/funding-fact';
import { FundingFactService } from './../../../_services/data/funding-fact.service';
import { FundingFactDefinitionsService } from './../../../_services/funding-fact-definitions.service';
import { Note } from './../../../models/note';
import { NoteService } from './../../../_services/data/note.service';
import { StakeholderService } from './../../../_services/data/stakeholder.service';
import { Stakeholder } from './../../../models/stakeholder';
import { Workbook } from 'exceljs';
import { TasksService } from './../../../_services/data/tasks.service';
import { Task } from './../../../models/task';
import { CustomerSystem } from './../../../models/customer-system';
import { AccountService } from './../../../_services/account.service';
import { CustomerSystemService } from './../../../_services/data/customer-system.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppBranch } from 'src/app/models/app';
import * as fileSaver from 'file-saver';
import { Project, ProjectConstants } from 'src/app/models/project';

@Component({
  selector: 'app-excel-export-dialog',
  templateUrl: './excel-export-dialog.component.html',
  styleUrls: ['./excel-export-dialog.component.scss']
})
export class ExcelExportDialogComponent implements OnInit {
  statusMsg = 'Export wird gestartet';
  progress = 20;
  private _branches: AppBranch[];
  constructor(
    public dialogRef: MatDialogRef<ExcelExportDialogComponent>,
    private _accountService: AccountService,
    private _systemsService: CustomerSystemService,
    private _tasksService: TasksService,
    private _stakeholdersService: StakeholderService,
    private _notesService: NoteService,
    private _fundingFactDefinitionsService: FundingFactDefinitionsService,
    private _fundingFactsService: FundingFactService,
    private _projectsService: ProjectService,
  ) {
    this._branches = this._accountService.getCurrentUser().customer.branches;
  }

  ngOnInit(): void {
    const branches = this._accountService.getCurrentUser().customer?.branches;
    if (branches?.length) {
      this.status('Daten werden geladen', 5);
      this.export(branches).then(_ => this.status('Export abgeschlossen', 100));
    } else {
      this.status('Keine Standorte!', 100);
    }
  }

  private export(branches: AppBranch[]): Promise<any> {
    return new Promise<any>(resolve => {
      const mainBranch = branches.find(x => x.isMainBranch);
      Promise.all([
        this.getSystems(branches),
        this.getTasks(branches),
        this.getStakeholders(branches),
        this.getNotes(branches),
        this._fundingFactDefinitionsService.getWithCriteria(),
        this.getFundingFacts(branches),
        this.getProjects(mainBranch),
      ]).then(results => {
        this.status('Daten geladen', 50)

        const workbook = new Workbook();
        this.status('Schreibe Systeme', 55)
        this.addSystems(workbook, results[0]);
        this.status('Schreibe Tasks', 60)
        this.addTasks(workbook, results[1]);
        this.status('Schreibe Stakeholder', 65)
        this.addStakeholders(workbook, results[2]);
        this.status('Schreibe Notizen', 70)
        this.addNotes(workbook, results[3]);
        this.status('Schreibe Fördertatbestände', 75)
        this.addFundingFacts(workbook, results[4], results[5], results[6]);
        this.status('Schreibe Projekte', 85)
        this.addProjects(workbook, results[6], results[5][mainBranch.id], results[2][mainBranch.id]);
        this.status('Speichern...', 95);

        const filename = `KHZG-Export-${new Date().valueOf()}.xlsx`;
        workbook.xlsx.writeBuffer().then(out => {
          const blob = new Blob([out], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(blob, filename + '-' + new Date().valueOf() + '.xlsx');
          resolve(true);
        });
      });
    });
  }

  addProjects(workbook: Workbook, data: ProjectData[], fundingFacts: FundingFact[], stakeholders: Stakeholder[]) {
    if (data?.length) {
      let i = 1;
      for (const project of data) {
        const sheet = workbook.addWorksheet('Projekt ' + (i++));
        let row = 1;
        let col = 1;
        sheet.getColumn(col).hidden = true;

        sheet.getCell(row, 2).value = 'Titel'
        sheet.getCell(row, 3).value = project.project.title;
        sheet.getRow(row).font = { bold: true }
        row++;
        sheet.getCell(row, 2).value = 'Id'
        sheet.getCell(row, 3).value = project.project.id;
        sheet.getRow(row).hidden = true;
        row++;
        sheet.getCell(row, 2).value = 'Typ'
        sheet.getCell(row, 3).value = ProjectConstants.Types[project.project.type];
        row++;
        sheet.getCell(row, 2).value = 'Projektstart'
        sheet.getCell(row, 3).value = project.project.projectStart;
        row++;
        sheet.getCell(row, 2).value = 'Start der Planungsphase'
        sheet.getCell(row, 3).value = project.project.planningStart;
        row++;
        sheet.getCell(row, 2).value = 'Gesamtkosten'
        sheet.getCell(row, 3).value = project.project.totalCosts;
        row++;
        sheet.getCell(row, 2).value = 'Standort'
        sheet.getCell(row, 3).value = project.project.branch;
        row++;
        sheet.getCell(row, 2).value = 'Schlagworte'
        sheet.getCell(row, 3).value = project.project.keywords.map(x => x.name).join(', ');
        row++;
        row++;

        sheet.getCell(row, 2).value = 'Stakeholder'
        sheet.getRow(row).font = { bold: true }
        row++;
        col = 1;
        sheet.getColumn(col).hidden = true;
        sheet.getCell(row, col++).value = 'Id';
        sheet.getCell(row, col++).value = 'Name';
        sheet.getCell(row, col++).value = 'Vorname';
        sheet.getCell(row, col++).value = 'Rolle';
        sheet.getCell(row, col++).value = 'E-Mail';
        sheet.getCell(row, col++).value = 'Telefon';
        sheet.getRow(row).font = { bold: true }
        row++;
        for (const item of project.projectStakeholders) {
          col = 1;
          const person = stakeholders.find(x => x.id === item.stakeholderId);
          sheet.getCell(row, col++).value = item.id;
          sheet.getCell(row, col++).value = person?.name;
          sheet.getCell(row, col++).value = person?.firstName;
          sheet.getCell(row, col++).value = item.role;
          sheet.getCell(row, col++).value = person?.mail;
          sheet.getCell(row, col++).value = person?.phone;
          row++;
        }
        row++;

        sheet.getCell(row, 2).value = 'Fördertatbestände'
        sheet.getRow(row).font = { bold: true }
        row++;
        col = 1;
        sheet.getColumn(col).hidden = true;
        col++;
        sheet.getCell(row, col++).value = 'Fördertatbestand';
        sheet.getCell(row, col++).value = 'Anteil';
        sheet.getRow(row).font = { bold: true }
        row++;
        for (const key of Object.keys(project.project.fundingFacts)) {
          const fundingFact = fundingFacts.find(x => x.id === key);
          const index = fundingFact.code.indexOf('.');
          const code = index < 0 ? fundingFact.code : fundingFact.code.substr(0, index);
          const value = project.project.fundingFacts[key];
          col = 1;
          col++;
          sheet.getCell(row, col++).value = code;
          sheet.getCell(row, col++).value = value;
          row++;
        }
        row++;

        sheet.getCell(row, 2).value = 'Projektkosten'
        sheet.getRow(row).font = { bold: true }
        row++;
        col = 1;
        sheet.getColumn(col).hidden = true;
        sheet.getCell(row, col++).value = 'Id';
        sheet.getCell(row, col++).value = 'Beschreibung';
        sheet.getCell(row, col++).value = 'Typ';
        sheet.getCell(row, col++).value = 'Intervall';
        sheet.getCell(row, col++).value = 'Gesamtbetrag';
        sheet.getCell(row, col++).value = 'Betrag (Sicherheit)';
        sheet.getCell(row, col++).value = 'Jahr';
        sheet.getCell(row, col++).value = 'Status';
        sheet.getRow(row).font = { bold: true }
        row++;
        for (const item of project.projectCosts) {
          col = 1;
          sheet.getCell(row, col++).value = item.id;
          sheet.getCell(row, col++).value = item.title;
          sheet.getCell(row, col++).value = CostLineConstants.Types[item.type];
          sheet.getCell(row, col++).value = CostLineConstants.Intervals[item.interval];
          sheet.getCell(row, col++).value = item.valueTotal;
          sheet.getCell(row, col++).value = item.valueSecurity;
          sheet.getCell(row, col++).value = item.year;
          sheet.getCell(row, col++).value = CostLineConstants.Status[item.status];
          row++;
        }
        row++;

        sheet.getCell(row, 2).value = 'Finanzierung'
        sheet.getRow(row).font = { bold: true }
        row++;
        col = 1;
        sheet.getColumn(col).hidden = true;
        sheet.getCell(row, col++).value = 'Id';
        sheet.getCell(row, col++).value = 'Beschreibung';
        sheet.getCell(row, col++).value = 'Anteil';
        sheet.getRow(row).font = { bold: true }
        row++;
        for (const item of project.projectFunding) {
          col = 1;
          sheet.getCell(row, col++).value = item.id;
          sheet.getCell(row, col++).value = item.name;
          sheet.getCell(row, col++).value = item.value;
          row++;
        }
        row++;

        sheet.getCell(row, 2).value = 'Standortzuordnung'
        sheet.getRow(row).font = { bold: true }
        row++;
        col = 1;
        sheet.getColumn(col).hidden = true;
        sheet.getCell(row, col++).value = 'Id';
        sheet.getCell(row, col++).value = 'Name';
        sheet.getCell(row, col++).value = 'Anteil';
        sheet.getRow(row).font = { bold: true }
        row++;
        for (const item of project.projectBranches) {
          col = 1;
          sheet.getCell(row, col++).value = item.id;
          sheet.getCell(row, col++).value = item.branchName;
          sheet.getCell(row, col++).value = item.ratio;
          row++;
        }
        row++;
      }
    }
  }

  addFundingFacts(workbook: Workbook, definitions: FundingFactDefinition[], data: { [key: string]: FundingFact[] }, projects: ProjectData[]) {
    if (data) {
      let i = 1;
      for (const branchId of Object.keys(data)) {
        const fundingFacts = data[branchId];
        if (fundingFacts?.length) {
          const branchName = this.getBranchName(branchId);
          const sheet = workbook.addWorksheet('Assessment ' + (i++));

          sheet.getCell(1, 2).value = branchName;

          let row = 3;
          let col = 1;
          sheet.getColumn(col).hidden = true;
          sheet.getCell(row, col++).value = 'Id';
          sheet.getCell(row, col++).value = 'Fördertatbestand';
          sheet.getCell(row, col++).value = 'Text';
          sheet.getCell(row, col++).value = 'Typ';
          sheet.getCell(row, col++).value = 'Kriterium';
          sheet.getCell(row, col++).value = 'Ist';
          sheet.getCell(row, col++).value = 'Soll';
          sheet.getCell(row, col++).value = 'Abdeckung durch Projekte';
          sheet.getRow(row).font = { bold: true }
          row++;
          if (definitions) {
            for (const fundingFactDefinition of definitions) {
              if (fundingFactDefinition.code < 'FTB2' || fundingFactDefinition.code > 'FTB6') {
                //continue;
              }
              const fundingFact = fundingFacts.find(x => x.code === fundingFactDefinition.code);
              col = 2;
              sheet.getCell(row, col++).value = fundingFactDefinition.code;
              sheet.getCell(row, col++).value = FundingFactConstants.shortNames[fundingFactDefinition.code.replace('.', '_')];
              col++;
              sheet.getCell(row, col++).value = 'Kriterium';
              sheet.getCell(row, col++).value = 'IST';
              sheet.getCell(row, col++).value = 'SOLL';
              sheet.getCell(row, col++).value = 'Projekte';
              sheet.getCell(row, col++).value = 'Kommentar';

              sheet.getRow(row).font = { italic: true }
              row++;
              if (fundingFactDefinition.criteria) {
                let lastType = '';
                for (const criteriumDefinition of fundingFactDefinition.criteria) {
                  const criterium = fundingFact?.criteria?.find(x => x.code === criteriumDefinition.code);
                  if (lastType && lastType !== criteriumDefinition.type) {
                    row++;
                  }
                  lastType = criteriumDefinition.type;
                  col = 1;
                  sheet.getCell(row, col++).value = criteriumDefinition.id;
                  sheet.getCell(row, col++).value = '';
                  sheet.getCell(row, col++).value = criteriumDefinition.name;
                  sheet.getCell(row, col++).value = criteriumDefinition.type;
                  sheet.getCell(row, col++).value = criteriumDefinition.code;
                  sheet.getCell(row, col++).value = criterium?.actualFulfillment?.value ?? 0;
                  if (criterium?.actualFulfillment?.hint) {
                    sheet.getCell(row, col - 1).note = criterium.actualFulfillment.hint;
                  }
                  sheet.getCell(row, col++).value = criterium?.targetFulfillment?.value ?? 0;
                  if (criterium?.targetFulfillment?.hint) {
                    sheet.getCell(row, col - 1).note = criterium.targetFulfillment.hint;
                  }
                  const coverage = projects.filter(x => x.projectCoverage?.find(y => y.fundingFact === fundingFactDefinition.code && y.criteria?.find(z => z === criteriumDefinition.code)));
                  sheet.getCell(row, col++).value = coverage.map(x => x.project.title ?? 'Projekt ohne Titel').join(', ');
                  sheet.getCell(row, col++).value = criterium?.note;
                  row++;
                }
              }
              row++;
            }
          }
        }
      }
    }
  }


  addNotes(workbook: Workbook, data: { [key: string]: Note[]; }) {
    const sheet = workbook.addWorksheet('Notizen');
    let row = 1;
    let col = 1;
    sheet.getColumn(col).hidden = true;
    sheet.getCell(row, col++).value = 'Id';
    sheet.getCell(row, col++).value = 'Standort';
    sheet.getCell(row, col++).value = 'Zeitstempel';
    sheet.getCell(row, col++).value = 'Titel';
    sheet.getCell(row, col++).value = 'Text';
    sheet.getRow(row).font = { bold: true }
    row++;
    if (data) {
      for (const branchId of Object.keys(data)) {
        const notes = data[branchId];
        if (notes?.length) {
          const branchName = this.getBranchName(branchId);
          for (const note of notes) {
            col = 1;
            sheet.getCell(row, col++).value = note.id;
            sheet.getCell(row, col++).value = branchName;
            sheet.getCell(row, col++).value = note.timestamp;
            sheet.getCell(row, col++).value = note.title;
            sheet.getCell(row, col++).value = note.text;
            row++;
          }
        }
      }
    }
  }

  addStakeholders(workbook: Workbook, data: { [key: string]: Stakeholder[]; }) {
    const sheet = workbook.addWorksheet('Stakeholder');
    let row = 1;
    let col = 1;
    sheet.getColumn(col).hidden = true;
    sheet.getCell(row, col++).value = 'Id';
    sheet.getCell(row, col++).value = 'Standort';
    sheet.getCell(row, col++).value = 'Name';
    sheet.getCell(row, col++).value = 'Vorname';
    sheet.getCell(row, col++).value = 'Titel';
    sheet.getCell(row, col++).value = 'Kürzel';
    sheet.getCell(row, col++).value = 'Position';
    sheet.getCell(row, col++).value = 'E-Mail';
    sheet.getCell(row, col++).value = 'Telefon';
    sheet.getRow(row).font = { bold: true }
    row++;
    if (data) {
      for (const branchId of Object.keys(data)) {
        const stakeholders = data[branchId];
        if (stakeholders?.length) {
          const branchName = this.getBranchName(branchId);
          for (const stakeholder of stakeholders) {
            col = 1;
            sheet.getCell(row, col++).value = stakeholder.id;
            sheet.getCell(row, col++).value = branchName;
            sheet.getCell(row, col++).value = stakeholder.name;
            sheet.getCell(row, col++).value = stakeholder.firstName;
            sheet.getCell(row, col++).value = stakeholder.title;
            sheet.getCell(row, col++).value = stakeholder.code;
            sheet.getCell(row, col++).value = stakeholder.position;
            sheet.getCell(row, col++).value = stakeholder.mail;
            sheet.getCell(row, col++).value = stakeholder.phone;
            row++;
          }
        }
      }
    }
  }

  addTasks(workbook: Workbook, data: { [key: string]: Task[]; }) {
    const sheet = workbook.addWorksheet('Tasks');
    let row = 1;
    let col = 1;
    sheet.getColumn(col).hidden = true;
    sheet.getCell(row, col++).value = 'Id';
    sheet.getCell(row, col++).value = 'Standort';
    sheet.getCell(row, col++).value = 'Aufgenommen am';
    sheet.getCell(row, col++).value = 'Was ist zu tun';
    sheet.getCell(row, col++).value = 'Priorität';
    sheet.getCell(row, col++).value = 'Zuständig';
    sheet.getCell(row, col++).value = 'Termin';
    sheet.getCell(row, col++).value = 'Erledigt am';
    sheet.getRow(row).font = { bold: true }
    row++;
    if (data) {
      for (const branchId of Object.keys(data)) {
        const tasks = data[branchId];
        if (tasks?.length) {
          const branchName = this.getBranchName(branchId);
          for (const task of tasks) {
            col = 1;
            sheet.getCell(row, col++).value = task.id;
            sheet.getCell(row, col++).value = branchName;
            sheet.getCell(row, col++).value = task.createdDate;
            sheet.getCell(row, col++).value = task.description;
            sheet.getCell(row, col++).value = task.priority;
            sheet.getCell(row, col++).value = task.assigneeStakeholder?.name ?? task.assignee;
            sheet.getCell(row, col++).value = task.dueDate;
            sheet.getCell(row, col++).value = task.doneDate;
            row++;
          }
        }
      }
    }
  }
  addSystems(workbook: Workbook, data: { [key: string]: CustomerSystem[]; }) {
    const sheet = workbook.addWorksheet('Systeme');
    let row = 1;
    let col = 1;
    sheet.getColumn(col).hidden = true;
    sheet.getCell(row, col++).value = 'Id';
    sheet.getCell(row, col++).value = 'Standort';
    sheet.getCell(row, col++).value = 'Typ';
    sheet.getCell(row, col++).value = 'Name';
    sheet.getCell(row, col++).value = 'Hersteller';
    sheet.getCell(row, col++).value = 'Jahr Beschaffung';
    sheet.getCell(row, col++).value = 'Jahr Einführung';
    sheet.getRow(row).font = { bold: true }
    row++;
    if (data) {
      for (const branchId of Object.keys(data)) {
        const customerSystems = data[branchId];
        if (customerSystems?.length) {
          const branchName = this.getBranchName(branchId);
          for (const customerSystem of customerSystems) {
            col = 1;
            sheet.getCell(row, col++).value = customerSystem.id;
            sheet.getCell(row, col++).value = branchName;
            sheet.getCell(row, col++).value = customerSystem.type;
            sheet.getCell(row, col++).value = customerSystem.name;
            sheet.getCell(row, col++).value = customerSystem.vendor;
            sheet.getCell(row, col++).value = customerSystem.aquisitionYear;
            sheet.getCell(row, col++).value = customerSystem.rolloutYear;
            row++;
          }
        }
      }
    }
  }

  private getBranchName(branchId: string): string {
    return this._branches?.find(x => x.id === branchId)?.name ?? branchId;
  }

  private getSystems(branches: AppBranch[]): Promise<{ [key: string]: CustomerSystem[] }> {
    return new Promise<{ [key: string]: CustomerSystem[] }>(resolve => {
      const promises = branches.map(branch => this._systemsService.getForBranch(branch.id));
      Promise.all(promises).then(results => {
        const result = {};
        for (let i = 0; i < branches.length; i++) {
          result[branches[i].id] = results[i];
        }
        resolve(result);
      });
    });
  }

  private getTasks(branches: AppBranch[]): Promise<{ [key: string]: Task[] }> {
    return new Promise<{ [key: string]: Task[] }>(resolve => {
      const promises = branches.map(branch => this._tasksService.getForBranch(branch.id));
      Promise.all(promises).then(results => {
        const result = {};
        for (let i = 0; i < branches.length; i++) {
          result[branches[i].id] = results[i];
        }
        resolve(result);
      });
    });
  }

  private getStakeholders(branches: AppBranch[]): Promise<{ [key: string]: Stakeholder[] }> {
    return new Promise<{ [key: string]: Stakeholder[] }>(resolve => {
      const promises = branches.map(branch => this._stakeholdersService.getForBranch(branch.id));
      Promise.all(promises).then(results => {
        const result = {};
        for (let i = 0; i < branches.length; i++) {
          result[branches[i].id] = results[i];
        }
        resolve(result);
      });
    });
  }

  private getNotes(branches: AppBranch[]): Promise<{ [key: string]: Note[] }> {
    return new Promise<{ [key: string]: Note[] }>(resolve => {
      const promises = branches.map(branch => this._notesService.getForBranch(branch.id));
      Promise.all(promises).then(results => {
        const result = {};
        for (let i = 0; i < branches.length; i++) {
          result[branches[i].id] = results[i];
        }
        resolve(result);
      });
    });
  }

  private getFundingFacts(branches: AppBranch[]): Promise<{ [key: string]: FundingFact[] }> {
    return new Promise<{ [key: string]: FundingFact[] }>(resolve => {
      const promises = branches.map(branch => this._fundingFactsService.getWithCriteria(branch.id));
      Promise.all(promises).then(results => {
        const result = {};
        for (let i = 0; i < branches.length; i++) {
          result[branches[i].id] = results[i];
        }
        resolve(result);
      });
    });
  }

  private getProjects(branch: AppBranch): Promise<ProjectData[]> {
    return new Promise<ProjectData[]>(resolve => {
      this._projectsService.getForBranch(branch.id).then(result => {
        Promise.all(result.map(x => this.getProjectDetails(x, branch.id))).then(results => {
          resolve(results);
        })
      })
    });
  }

  private getProjectDetails(project: Project, branchId: string): Promise<ProjectData> {
    const projectId = project.id;
    return new Promise<ProjectData>(resolve => {
      Promise.all([
        this._projectsService.getCostLines(projectId, branchId),
        this._projectsService.getFundings(projectId, branchId),
        this._projectsService.getStakeholders(projectId, branchId),
        this._projectsService.getBranches(projectId, branchId),
        this._projectsService.getCoverage(projectId, branchId),
      ]).then(results => {
        const result: ProjectData = {
          project: project,
          projectCosts: results[0],
          projectFunding: results[1],
          projectStakeholders: results[2],
          projectBranches: results[3],
          projectCoverage: results[4],
        };
        resolve(result);
      });
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  private status(msg: string, progress: number) {
    this.statusMsg = msg;
    this.progress = progress;
  }
}

export interface ProjectData {
  project: Project;
  projectCosts: CostLine[];
  projectStakeholders: ProjectStakeholder[];
  projectFunding: ProjectFunding[];
  projectBranches: ProjectBranch[];
  projectCoverage: ProjectCoverage[];
}