<button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()" [color]="filter?.checked ? 'black' : 'warn'">
    <mat-icon *ngIf="!filter?.checked" style="font-size: 1em; margin-right: -0.8em;">filter_list_alt</mat-icon>
    <mat-icon>arrow_drop_down</mat-icon>
</button>
<mat-menu #menu="matMenu">
    <div>
        <mat-form-field appearance="fill">
            <input placeholder="Suche" #search matInput (click)="$event.stopPropagation();" />
            <button *ngIf="search.value" matSuffix mat-icon-button (click)="search.value=''; $event.stopPropagation();">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <ul style="list-style-type: none; padding-left: 0px; max-height: 250px; ">
            <li>
                <mat-checkbox [indeterminate]="filter?.indeterminate" [checked]="filter?.checked"
                    (click)="filter.toggle(); setFilter(); $event.stopPropagation();">
                    (Alles auswählen)
                </mat-checkbox>
            </li>
            <li *ngFor="let item of getItems(search.value)">
                <mat-checkbox [checked]="item.checked" (click)="toggleItem(item); setFilter(); $event.stopPropagation();">
                    {{item.name}}
                </mat-checkbox>
            </li>
        </ul>
    </div>
</mat-menu>