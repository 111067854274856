import { CostLine } from './cost-line';
import { ServiceClass } from './../_services/service-base';
export class Project implements ServiceClass {
    id: string;
    code: string;
    title: string;
    description: string;
    descriptionSecurity: string;
    type: string;
    projectStart: Date;
    planningStart: Date;
    complexity: number;
    fundingFacts: { [key: string]: number };
    keywords: Keyword[];
    branch?: string;
    branchId?: string;
    fundingFactCodes?: string[];
    totalCosts?: number;
    khzgCosts?: number;
    khzgCostsOnce?: number;
    khzgCostsRepeating?: number;
    securityCosts?: number;
    includeInCalculation: boolean;
    productName?: string;
    productVendor?: string;
    firstCostYear?: string;
    fundingFactsWithRatio?: { [key: string]: number };
    branches?: { [key: string]: number };
    stakeholders?: string[];
    priority: string;
    status: string;
    assignee?: string;
    fundingChance?: string;
    layers?: string[];
    costsByType: { [key: string]: number };
    branchNames?: string[];
    additionalCriteria?: string[];
    costs?: CostLine[];
    sortCode: string;
    awardType?: string;
    externalProjectId?: string;
    masterProjectId?: string;
    url?: string;
    funding?: { [key: string]: number };
}

export class Keyword {
    public id?: string;
    public name: string;
}

export class AdminProject implements ServiceClass {
    id: string;
    branchId: string;
    customerId: string;
    title: string;
    description: string;
    descriptionSecurity: string;
}

export class ProjectConstants {
    public static readonly Types = {
        'funding-fact': 'FTB-Hauptprojekte',
        'datasecurity': 'Informationssicherheit und Datenschutz',
        'datamanagement': 'Datenmanagement und Interoperabilität',
        'infrastructure': 'Weitere digitale Infrastruktur'
    };

    public static readonly Priorities = {
        'submitted': 'beantragt',
        'high': 'muss',
        'medium': 'sollte',
        'low': 'könnte',
    }

    public static readonly Status = {
        'red': 'rot',
        'yellow': 'gelb',
        'green': 'grün',
    }

    public static readonly FundingChances = {
        'none': 'keine Förderung',
        'low': 'gering',
        'likely': 'wahrscheinlich',
        'safe': 'sicher',
    }

    public static readonly Layers = {
        '1': '(1) IT-Management',
        '2': '(2) Infrastruktur',
        '3': '(3) Primärsysteme',
        '4': '(4) Datenkonsolidierung',
        '5': '(5) Infrastrukturelle Interoperabilität',
        '6': '(5) Semantische Interoperabilität',
        '7': '(7) Werkzeuge/Algorithmen',
        '8': '(8) Neue Datentypen',
    }

    public static readonly AwardTypes = {
        'beschraenkte-ausschreibung': 'beschränkte Ausschreibung',
        'freihaendige-vergabe': 'freihändige Vergabe',
        'innovationspartnerschaft': 'Innovationspartnerschaft',
        'nichtoffenes-verfahren': 'nichtoffenes Verfahren',
        'offenes-verfahren': 'offenes Verfahren',
        'oeffentliche-ausschreibung': 'öffentliche Ausschreibung',
        'verhandlungsverfahren': 'Verhandlungsverfahren/Verhandlungsvergabe',
        'wettbewerblicher-dialog': 'wettbewerblicher Dialog',
    }
}
