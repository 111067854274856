import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  constructor(private http: HttpClient) { }

  public get(filePath: string): Promise<any> {
    return this.http.get('assets/' + filePath, { responseType: 'arraybuffer' }).toPromise();
  }

  public getString(filePath: string): Promise<string> {
    return this.http.get('assets/' + filePath, { responseType: 'text' }).toPromise();
  }

  public download(filePath: string): Promise<any> {
    return this.http.get(`${environment.apiUrl}/file/?file=${filePath}`, {
      responseType: 'blob'
    }).toPromise();
  }
}

export interface Download {
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE'
  progress: number
  content: Blob | null
}