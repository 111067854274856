import { CustomerService } from './../_services/customer.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppCustomer } from '../models/app';
import { AccountService } from '../_services';

@Component({
  selector: 'app-switch-customer-dialog',
  templateUrl: './switch-customer-dialog.component.html',
  styleUrls: ['./switch-customer-dialog.component.scss']
})
export class SwitchCustomerDialogComponent implements OnInit {

  currentCustomer: AppCustomer;
  customers: AppCustomer[];
  filteredValues: AppCustomer[];
  selectedValue: any;

  constructor(
    public dialogRef: MatDialogRef<SwitchCustomerDialogComponent>,
    private _accountService: AccountService
  ) {
    const currentUser = this._accountService.getCurrentUser();
    this.currentCustomer = currentUser?.customer;
    if (currentUser?.customers) {
      this.customers = currentUser.customers;
    }
  }

  get IsValueSelected(): boolean {
    return (this.selectedValue && this.selectedValue.id);
  }

  ngOnInit(): void {
    this.filterValues();
  }

  filterValues() {
    if (this.selectedValue && !this.selectedValue.id) {
      this.filteredValues = this._filter(this.selectedValue);
      return;
    }

    this.filteredValues = this.customers;
  }

  displayFn(option: AppCustomer): string {
    return (option?.name ?? '') + (option?.code ? ` (${option.code})` : '');
  }

  private _filter(name: string): AppCustomer[] {
    const filterValue = name.toLowerCase();

    return this.customers.filter(option => option.name.toLowerCase().indexOf(filterValue) !== -1 || option?.code?.toLowerCase().startsWith(filterValue));
  }

  doAction() {
    if (this.IsValueSelected) {
      const selectedCustomer: AppCustomer = this.selectedValue;
      this._accountService.switchCustomer(selectedCustomer)
      if(selectedCustomer.branches.length > 0) {
        this._accountService.switchBranch(selectedCustomer.branches[0].id);
      }
    }
    this.dialogRef.close({ event: 'Switch' });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }
}
