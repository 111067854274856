import { MaterialModule } from './../material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuListItemComponent } from './menu-list-item/menu-list-item.component';
import { BusyIndicatorComponent } from './busy-indicator/busy-indicator.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationsDialogComponent } from './dialogs/notifications-dialog/notifications-dialog.component';
import { LockedBannerComponent } from './locked-banner/locked-banner.component';
import { ProgressComponent } from './progress/progress.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { GenericMessageDialogComponent } from './dialogs/generic-message-dialog/generic-message-dialog.component';
import { ContextHelpComponent } from './context-help/context-help.component';
import { ColumnFilterComponent } from './column-filter/column-filter.component';
import { GenericBannerComponent } from './generic-banner/generic-banner.component';
import { FormsModule } from '@angular/forms';
import { FileViewerComponent } from './file-viewer/file-viewer.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';



@NgModule({
  declarations: [MenuListItemComponent, BusyIndicatorComponent, NotificationsComponent, NotificationsDialogComponent, LockedBannerComponent, ProgressComponent, SidenavComponent, GenericMessageDialogComponent, ContextHelpComponent, ColumnFilterComponent, GenericBannerComponent, FileViewerComponent],
  imports: [
    MaterialModule,
    CommonModule,
    FormsModule,
    PdfJsViewerModule,
  ],
  exports: [
    MenuListItemComponent,
    BusyIndicatorComponent,
    NotificationsComponent,
    LockedBannerComponent,
    ProgressComponent,
    SidenavComponent,
    ContextHelpComponent,
    ColumnFilterComponent,
    GenericBannerComponent,
    FileViewerComponent,
  ]
})
export class ComponentsModule { }
