import { Task } from './../models/task';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  protected get BaseUrl(): string {
    return `${environment.apiUrl}/admin/tasks`;
  }

  constructor(private http: HttpClient) { }

  public get(customerCode: string, assigneeFilter: string, showCompleted: boolean): Promise<Task[]> {
    return this.http.get<Task[]>(`${this.BaseUrl}?customerCode=${customerCode ?? ''}&filterAssignee=${assigneeFilter ?? ''}&showCompleted=${showCompleted ?? false}`).toPromise();
  }
}
