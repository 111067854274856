<header>
  <div class="toolbar" role="banner">
    <div style="margin-left: 10px">
      <h1>{{appTitle}}</h1>
      <div *ngIf="User && !User.IsTemporary">
        <span>Herzlich willkommen, {{User.name}}!</span>
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button *ngIf="User.IsAdmin" mat-menu-item (click)="switchCustomer()">
            <mat-icon>domain</mat-icon>
            <span>Kunde wechseln</span>
          </button>
          <button *ngIf="User.HasBranches" mat-menu-item (click)="switchBranch()">
            <mat-icon>domain</mat-icon>
            <span>Standort wechseln</span>
          </button>
          <button *ngIf="User.IsAdmin" mat-menu-item [routerLink]="'/all-tasks'">
            <mat-icon>assignment</mat-icon>
            <span>Task-Übersicht</span>
          </button>
          <button mat-menu-item (click)="exportExcel()">
            <mat-icon>save_alt</mat-icon>
            <span>Excel-Export</span>
          </button>
          <button mat-menu-item (click)="exportProjects()">
            <mat-icon>save_alt</mat-icon>
            <span>Projekt-Export</span>
          </button>
          <button *ngIf="User.IsAdmin" mat-menu-item (click)="exportAssessment()">
            <mat-icon>save_alt</mat-icon>
            <span>Assessment-Export</span>
          </button>
          <button mat-menu-item disabled>
            <mat-icon>settings</mat-icon>
            <span>Einstellungen</span>
          </button>
          <button *ngIf="User.hasLicense('Assessment')" mat-menu-item [routerLink]="'/assessment'">
            <mat-icon>home</mat-icon>
            <span>Assessment</span>
          </button>
          <button *ngIf="User.hasLicense('Reporting')" mat-menu-item [routerLink]="'/reporting'">
            <mat-icon>fact_check</mat-icon>
            <span>Berichtswesen</span>
          </button>
          <button *ngIf="User.IsAdmin" mat-menu-item [routerLink]="'/administration'">
            <mat-icon>build</mat-icon>
            <span>Administration</span>
          </button>
          <button *ngIf="User.IsAdmin" mat-menu-item [routerLink]="'/administration/projects'">
            <mat-icon>search</mat-icon>
            <span>Globale Projektsuche</span>
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon>
            <span>Abmelden</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="spacer"></div>
    <div *ngIf="User" style="display: flex; flex-direction: column;">
      <span>Benutzer: {{User.username}}</span>
      <span>Kunde: {{User.customer?.name}}</span>
      <span *ngIf="User.HasBranches">Standort: {{Branch?.name}}</span>
    </div>
    <img height="90" alt="Digital Avantgarde Logo" src="assets/img/logo.png" />
  </div>
</header>

<main class="page-main">
  <router-outlet></router-outlet>
</main>

<!-- credits -->
<footer>
  &copy;{{now | date:"YYYY"}}&nbsp;<a rel="noopener noreferrer" href="https://www.digital-avantgarde.de/"
    target="_blank">Digital Avantgarde GmbH</a>
</footer>
<app-notifications></app-notifications>