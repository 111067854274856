import { AccountService } from './../account.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerSystem } from 'src/app/models/customer-system';
import { DataServiceBase } from './data-service-base';

@Injectable({
  providedIn: 'root'
})
export class CustomerSystemService extends DataServiceBase<CustomerSystem> {
  protected dataType = 'systems';

  constructor(protected http: HttpClient, private accountService: AccountService) {
    super();

    accountService.branch.subscribe(x => this.branch = x);
    accountService.user.subscribe(x => this.customer = x?.customer);
  }
}
