import { AssetsService } from './../_services/assets.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {

  constructor(private _router: Router, private _assetsService: AssetsService) {

  }

  ngOnInit(): void {
    const url = this._router.routerState.snapshot.url;
    if (url.startsWith('/assets/')) {
      const filename = url.substr(url.lastIndexOf('/') + 1);
      this._assetsService.download(encodeURIComponent(url.substr(8))).then(blob => {
        saveAs(blob, filename);
        this._router.navigate(['/']);
      }).catch(_ => {
        alert('Ein Fehler ist aufgetreten!')
        this._router.navigate(['/']);
      });
    }
  }
}
