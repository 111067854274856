<h1>Taskübersicht für {{user.name}}</h1>
<div>
  <section class="view-config">
    <label class="view-margin">Anzeige:</label>
    <mat-radio-group [(ngModel)]="currentView">
      <mat-radio-button value="my-items" class="view-margin">Mir zugewiesen</mat-radio-button>
      <mat-radio-button value="diav" class="view-margin">Digital Avantgarde</mat-radio-button>
      <mat-radio-button value="all-items" class="view-margin">Alle</mat-radio-button>
    </mat-radio-group>
    <mat-checkbox class="view-margin" [(ngModel)]="showCompleted">Abgeschlossene anzeigen</mat-checkbox>
    <mat-form-field class="view-margin">
      <mat-label>Kunde</mat-label>
      <input matInput type="text" [(ngModel)]="customerCode">
      <button mat-button *ngIf="customerCode" matSuffix mat-icon-button aria-label="Clear" (click)="customerCode=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </section>
</div>
<div class="container text-center" style="overflow: auto; height: 100%;">
  <table mat-table [dataSource]="tableData.dataSource" #table class="my-table mat-elevation-z8" style="min-width: 80%;">
    <ng-container matColumnDef="customer">
      <th mat-header-cell *matHeaderCellDef>Kunde</th>
      <td mat-cell *matCellDef="let element">{{element.branch.customer.code}}</td>
    </ng-container>
    <ng-container matColumnDef="branch">
      <th mat-header-cell *matHeaderCellDef>Standort</th>
      <td mat-cell *matCellDef="let element">{{element.branch.name}}</td>
    </ng-container>
    <ng-container matColumnDef="createdDate">
      <th mat-header-cell *matHeaderCellDef>Aufgenommen am</th>
      <td mat-cell *matCellDef="let element">{{element.createdDate | date:'shortDate'}}</td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Was ist zu tun</th>
      <td mat-cell *matCellDef="let element">{{element.description}}</td>
    </ng-container>
    <ng-container matColumnDef="priority">
      <th mat-header-cell *matHeaderCellDef>Priorität</th>
      <td mat-cell *matCellDef="let element">{{element.priority | translateKey:taskPriorities }}</td>
    </ng-container>
    <ng-container matColumnDef="assignee">
      <th mat-header-cell *matHeaderCellDef>Zuständig</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.assigneeStakeholder">{{element.assignee}}</span>
        <span *ngIf="element.assigneeStakeholder">{{element.assigneeStakeholder.name}},
          {{element.assigneeStakeholder.firstName}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="dueDate">
      <th mat-header-cell *matHeaderCellDef>Termin</th>
      <td mat-cell *matCellDef="let element">{{element.dueDate | date:'shortDate'}}</td>
    </ng-container>
    <ng-container matColumnDef="doneDate">
      <th mat-header-cell *matHeaderCellDef>Erledigt am</th>
      <td mat-cell *matCellDef="let element">{{element.doneDate | date:'shortDate'}}</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td mat-cell *matCellDef="let element" class="action-link">
        <button mat-icon-button color="primary" (click)="openNoteDialog(element)" [matTooltip]="element.note">
          <mat-icon>speaker_notes</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="openDialog('Update',element)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button color="warn" (click)="openDialog('Delete',element)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableData.displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableData.displayedColumns;"></tr>
  </table>
</div>
