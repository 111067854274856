import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NavItem } from 'src/app/models/nav-item';
import { NavService } from 'src/app/_services/nav.service';
import { AccountService } from 'src/app/_services';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuListItemComponent implements OnInit {
  expanded = false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: NavItem;
  @Input() depth: number;
  @Input() baseRoute: string;
  @Input() iconOnly: boolean;

  itemActive = false;

  constructor(
    private route: ActivatedRoute,
    public navService: NavService,
    private accountService: AccountService,
    public router: Router) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  public get isActive(): boolean {
    if (!this.item.route) {
      return this.itemActive;
    }
    const route = `${this.baseRoute}${this.baseRoute ? "/" : ""}${this.item.route}`;
    return this.itemActive || this.router.url === route || this.router.url.startsWith(`${route}/`);
  }

  public get isDisabled(): boolean {
    return this.item.disabled === true || (this.item.license && !this.accountService.getCurrentUser().hasLicense(this.item.license));
  }

  ngOnInit() {
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        let itemUrl = this.getRoute(this.item);
        if (itemUrl.substring(0, 1) === '/') {
          itemUrl = itemUrl.substring(1);
        }
        this.expanded = url.indexOf(`/${itemUrl}`) === 0;
        this.ariaExpanded = this.expanded;
      }
    });
  }

  onItemSelected(item: NavItem) {
    if (!item.children || !item.children.length) {
      if (item.function) {
        this.itemActive = true;
        item.function();
        this.itemActive = false;
      } else if (item.route) {
        this.router.navigate([this.getRoute(item)]);
      }
      //this.navService.closeNav();
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
      if (this.expanded) {
        this.onItemSelected(item.children[0]);
      }
    }
  }

  private getRoute(item: NavItem): string {
    if (this.baseRoute && this.baseRoute !== 'undefined') {
      return `${this.baseRoute}/${item.route}`;
    } else {
      return item.route;
    }
  }
}
