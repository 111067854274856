import {EventEmitter, Injectable} from '@angular/core';
import {Event, NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavService {
  private appDrawerSubject = new BehaviorSubject<any>(undefined);
  public appDrawer: Observable<any>;
  public currentUrl = new BehaviorSubject<string>(undefined);

  constructor(private router: Router) {
    this.appDrawer = this.appDrawerSubject.asObservable();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });
  }

  public setAppDrawer(appDrawer: any): void {
    this.appDrawerSubject.next(appDrawer);
  }

  public closeNav(): void {
    this.appDrawerSubject.value.close();
  }

  public openNav():void {
    this.appDrawerSubject.value.open();
  }
}
