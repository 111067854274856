import { LoadingService } from './../../_services/loading.service';
import { Component, Input, OnInit } from '@angular/core';
import { debounce } from 'rxjs/operators';
import { interval } from 'rxjs';

@Component({
  selector: 'app-busy-indicator',
  templateUrl: './busy-indicator.component.html',
  styleUrls: ['./busy-indicator.component.scss']
})
export class BusyIndicatorComponent implements OnInit {
  @Input() public isBusy: boolean;
  @Input() public useService: boolean;


  constructor(private _loadingService: LoadingService) { }

  ngOnInit(): void {
    if (this.useService) {
      this._loadingService.IsLoading.pipe(debounce(() => interval(100))).subscribe(loading => {
        this.isBusy = loading
      });
    }
  }

}
