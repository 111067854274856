import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { AccountService } from 'src/app/_services';

@Component({
  selector: 'app-save-dialog',
  templateUrl: './save-dialog.component.html',
  styleUrls: ['./save-dialog.component.scss']
})
export class SaveDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SaveDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private debug: SaveDebugData,
    private accountService: AccountService,
  ) {
    if (this.accountService.getCurrentUser().IsAdmin && this.debug) {
      try {
        console.log("oldValue", JSON.parse(debug.oldValue));
        console.log("newValue", JSON.parse(debug.newValue));
      } catch { }
    }
  }

  ngOnInit(): void { }

  doAction(action: string) {
    this.dialogRef.close({ event: action });
  }
}

export interface SaveDebugData {
  oldValue: string;
  newValue: string;
}