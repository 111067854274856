import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Stakeholder } from 'src/app/models/stakeholder';
import { TableDialogData } from 'src/app/models/table-dialog-data';
import { Task, TaskConstants } from 'src/app/models/task';
import { StaticListHelper } from 'src/app/_helpers/static-list-helper';
import { StakeholderService } from 'src/app/_services/data/stakeholder.service';
import { StakeholderDialogComponent } from '../stakeholder-dialog/stakeholder-dialog.component';

@Component({
  selector: 'app-tasks-dialog',
  templateUrl: './tasks-dialog.component.html',
  styleUrls: ['./tasks-dialog.component.scss']
})
export class TasksDialogComponent implements OnInit {
  action: string;
  model: Task;
  stakeholders: Stakeholder[];
  filteredStakeholders: Stakeholder[];
  taskPriorities = StaticListHelper.ToList(TaskConstants.Priorities);

  actionAdd = {
    callback: () => {
      this.addStakeholder();
    }
  };

  selectedStakeholder: any;


  constructor(
    public dialogRef: MatDialogRef<TasksDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: TableDialogData<Task>,
    public dialog: MatDialog,
    private _stakeholderService: StakeholderService
  ) {
    this.model = JSON.parse(JSON.stringify(data.data));
    this.action = data.action;
    this.stakeholders = data.additional?.stakeholders;
  }

  ngOnInit() {
    this.model.assigneeStakeholder = this.stakeholders.find(x => x.id === this.model.assigneeStakeholderId);
    this.selectedStakeholder = this.model.assigneeStakeholder;
    this.filterStakeholders();
  }

  filterStakeholders() {
    if (this.selectedStakeholder && this.selectedStakeholder.id) {
      this.model.assigneeStakeholder = this.selectedStakeholder;
      this.model.assigneeStakeholderId = this.selectedStakeholder.id;
      this.model.assignee = '';
    } else if (this.selectedStakeholder && this.selectedStakeholder.callback) {
      this.selectedStakeholder.callback();
    } else {
      this.model.assigneeStakeholder = undefined;
      this.model.assigneeStakeholderId = '';
      this.model.assignee = this.selectedStakeholder;
      const filter = this.selectedStakeholder as string;
      if (filter) {
        this.filteredStakeholders = this._filter(filter);
        return;
      }
    }
    this.filteredStakeholders = this.stakeholders;
  }

  displayFn(option: Stakeholder): string {
    return option && option.name ? option.name + ', ' + option.firstName : '';
  }

  private _filter(name: string): Stakeholder[] {
    const filterValue = name.toLowerCase();

    return this.stakeholders.filter(option => option.name?.toLowerCase().indexOf(filterValue) === 0
      || option.firstName?.toLowerCase().indexOf(filterValue) === 0
      || option.mail?.toLowerCase().indexOf(filterValue) === 0);
  }

  doAction() {
    if (!this.model.assigneeStakeholderId) {
      this.model.assigneeStakeholderId = null;
    }
    this.dialogRef.close({ event: this.action, data: this.model });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  addStakeholder() {
    const data: TableDialogData<Stakeholder> = {
      action: 'Add',
      data: new Stakeholder(),
    };

    const dialogRef = this.dialog.open(StakeholderDialogComponent, {
      width: '450px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.event == 'Add') {
        this._stakeholderService.add(result.data).then(x => {
          this.stakeholders.push(x);
          this.selectedStakeholder = x;
          this.filterStakeholders();
        });
      }
    });
  }
}
