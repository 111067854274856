import { Component, Input, OnInit } from '@angular/core';
import { NavItem } from 'src/app/models/nav-item';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  @Input() navItems: NavItem[];
  @Input() baseRoute: string;
  @Input() navId: string;
  @Input() navTitle: string;
  @Input() icon: string;
  collapsed: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
    if (this.navId) {
      this.collapsed = localStorage.getItem(`sidenav-${this.navId}`) === "collapsed";
    }
  }

  public toggleNav() {
    this.collapsed = !this.collapsed;
    if (this.navId) {
      localStorage.setItem(`sidenav-${this.navId}`, this.collapsed ? "collapsed" : "expanded");
    }
  }

}
