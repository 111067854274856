import { LockHandler } from './../../_helpers/lock-aware';
import { Component, Input, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-locked-banner',
  templateUrl: './locked-banner.component.html',
  styleUrls: ['./locked-banner.component.scss']
})
export class LockedBannerComponent implements OnInit, AfterViewInit {
  @Input() public lockHandler: LockHandler;
  @Input() public changed?: boolean;

  public initialized = false;

  constructor() { }

  ngAfterViewInit(): void {
    this.initialized = true;
  }

  ngOnInit(): void {
  }

  public get isSaveVisible(): boolean {
    return this.lockHandler.isLockedForMe && this.lockHandler?.canSave;
  }

  public get isSaveDisabled(): boolean {
    return this.changed !== true;
  }
}
