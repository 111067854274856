<h1 *ngIf="model.description" mat-dialog-title><strong>{{model.description}}</strong></h1>
<h1 *ngIf="!model.description" mat-dialog-title><strong>Task hinzufügen</strong></h1>
<div mat-dialog-content>
  <div style="display: flex; flex-direction: column;" *ngIf="action != 'Delete'; else elseTemplate">
    <mat-form-field appearance="fill">
      <input placeholder="Was ist zu tun" matInput [(ngModel)]="model.description" [disabled]="action === 'View'" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Priorität</mat-label>
      <mat-select [(ngModel)]="model.priority" [disabled]="action === 'View'">
        <mat-option *ngFor="let item of taskPriorities" value="{{item.key}}">{{item.value}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <input placeholder="Zuständig" matInput [(ngModel)]="selectedStakeholder" [matAutocomplete]="auto"
        (ngModelChange)="filterStakeholders();" [disabled]="action === 'View'" />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option [value]="actionAdd">
          (Stakeholder anlegen)
        </mat-option>
        <mat-option *ngFor="let option of filteredStakeholders" [value]="option">
          {{option.name}}, {{option.firstName}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <input placeholder="Termin" matInput [matDatepicker]="dpDueDate" [(ngModel)]="model.dueDate" [disabled]="action === 'View'">
      <mat-datepicker-toggle matSuffix [for]="dpDueDate"></mat-datepicker-toggle>
      <mat-datepicker #dpDueDate></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <input placeholder="Erledigt am" matInput [matDatepicker]="dpDoneDate" [(ngModel)]="model.doneDate" [disabled]="action === 'View'">
      <mat-datepicker-toggle matSuffix [for]="dpDoneDate"></mat-datepicker-toggle>
      <mat-datepicker #dpDoneDate></mat-datepicker>
    </mat-form-field>
  </div>
  <ng-template #elseTemplate>
    Soll der Task <strong>{{model.description}}</strong> wirklich gelöscht werden?
  </ng-template>
</div>
<div mat-dialog-actions style="display: flex; flex-direction: row; justify-content: flex-end;">
  <button [disabled]="action === 'View'" mat-button (click)="doAction()">OK</button>
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Abbrechen</button>
</div>
