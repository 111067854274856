<h1><strong>Notiz</strong></h1>
<form>
  <div mat-dialog-content>
    <div style="display: flex; flex-direction: column;">
      <mat-form-field appearance="fill">
        <textarea placeholder="Text" matInput [(ngModel)]="model" name="text" rows="20" [disabled]="action === 'View'"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions style="display: flex; flex-direction: row; justify-content: flex-end;">
    <button [disabled]="action === 'View'" type="submit" mat-button (click)="doAction()">OK</button>
    <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Abbrechen</button>
  </div>
</form>
