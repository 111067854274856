import { Component, Input, OnInit, Optional } from '@angular/core';

@Component({
  selector: 'app-context-help',
  templateUrl: './context-help.component.html',
  styleUrls: ['./context-help.component.scss']
})
export class ContextHelpComponent implements OnInit {
  @Input() public id: string;
  @Input() public title: string = "Hinweise zu dieser Seite";
  private hidden = false;

  constructor() {
  }

  private get key(): string {
    if (this.id) {
      return `help-${this.id}`;
    }
    return null;
  }

  public get visible(): boolean {
    return !this.hidden;
  }

  ngOnInit(): void {
    if (this.key) {
      const value = localStorage.getItem(this.key);
      this.hidden = value === "true";
    }
  }

  public hide(permanent: boolean) {
    this.hidden = true;
    if (permanent) {
      localStorage.setItem(this.key, "true");
    }
  }
}
