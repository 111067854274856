import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ServiceClass } from "../_services/service-base";

export class TableData<T extends ServiceClass> {
  private _data: T[];
  private _sort: MatSort;
  displayedColumns: string[] = [];

  dataSource = new MatTableDataSource<T>();
  private _sortId: string;
  sortData: { [key: string]: (data: T[], sort: MatSort) => T[]; };

  constructor(columns?: string[], sortId?: string) {
    this.displayedColumns = columns ?? [];
    this._data = [];
    this.updateDataSource();
    this._sortId = sortId;
  }

  private updateDataSource() {
    this.dataSource.data = this._data;
  }

  get data(): T[] {
    return this._data;
  }

  set data(value: T[]) {
    this._data = value;
    this.updateDataSource();
  }

  set sortId(value: string) {
    this._sortId = value;
  }

  get sort(): MatSort {
    return this._sort;
  }

  set sort(value: MatSort) {
    this._sort = value;
    this.dataSource.sort = this._sort;
  }

  get filter(): string {
    return this.dataSource.filter;
  }

  set filter(value: string) {
    this.dataSource.filter = value;
  }

  set filterPredicate(value: (data: T, filter: string) => boolean) {
    this.dataSource.filterPredicate = value;
  }

  get filteredData(): T[] {
    return this.dataSource.filteredData;
  }

  getActiveSort(): string {
    const stored = localStorage.getItem(`sort-${this._sortId}`);
    if (stored) {
      const sort = JSON.parse(stored);
      return sort?.active;
    }
    return undefined;
  }

  getSortDirection(): string {
    const stored = localStorage.getItem(`sort-${this._sortId}`);
    if (stored) {
      const sort = JSON.parse(stored);
      return sort?.direction;
    }
    return undefined;
  }

  storeSort(sort: any) {
    if (this._sortId) {
      localStorage.setItem(`sort-${this._sortId}`, JSON.stringify(sort));
    }
  }

  updateSort(sort: any) {
    if (this.sortData && sort?.active) {
      const sortData = this.sortData[sort.active];
      if (sortData) {
        this.dataSource.sortData = sortData;
      }
    }
  }

  add(item: T) {
    this._data.push(item);
    this.updateDataSource();
  }
  update(item: T) {
    const current = this._data.find(y => y.id === item.id);
    if (current) {
      const index = this._data.indexOf(current);
      this._data.splice(index, 1, item);
    }
    this.updateDataSource();
  }
  delete(item: T) {
    const current = this._data.find(y => y.id === item.id);
    if (current) {
      const index = this._data.indexOf(current);
      this._data.splice(index, 1);
    }
    this.updateDataSource();
  }
}