import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-column-filter',
  templateUrl: './column-filter.component.html',
  styleUrls: ['./column-filter.component.scss']
})
export class ColumnFilterComponent implements OnInit {
  @Input() filter: ColumnFilter = null;
  @Output() applyFilters = new EventEmitter();

  constructor() {
  }

  public getItems(search: string) {
    return this.filter?.values.filter(x => (search === "" || x.name.toLowerCase().indexOf(search.toLowerCase()) >= 0));
  }

  setFilter(): void {
    this.applyFilters.emit(this.filter);
  }

  toggleItem(item: any) {
    setTimeout(() => {
      item.checked = !item.checked;
    }, 0);
  }

  ngOnInit(): void {
  }

}

export class ColumnFilter {
  public key: string;
  public values: { name: string, checked: boolean, internalValue: any }[];

  public constructor(key: string, values: { name: string, checked: boolean, internalValue: any }[]) {
    this.key = key;
    this.values = values.filter((thing, i, arr) => arr.findIndex(t => t.internalValue === thing.internalValue) === i);
  }

  public get checked(): boolean {
    const count = this.values.length
    const checked = this.values.filter(x => x.checked).length
    return count === checked;
  }

  public get indeterminate(): boolean {
    const count = this.values.length
    const checked = this.values.filter(x => x.checked).length
    return checked !== 0 && count !== checked;
  }

  public toggle(): void {
    setTimeout(() => {
      if (!this.checked) {
        this.values.forEach(x => x.checked = true);
      } else {
        this.values.forEach(x => x.checked = false);
      }
    }, 0);
  }
}