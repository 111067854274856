import { BehaviorSubject, Subject } from 'rxjs';
import { StakeholderService } from './../../_services/data/stakeholder.service';
import { TasksDialogComponent } from './../dialogs/tasks-dialog/tasks-dialog.component';
import { TasksService } from './../../_services/data/tasks.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LockService } from 'src/app/_services/lock-service';
import { TasksService as AllTasksService } from './../../_services/tasks.service';
import { AppUser } from './../../models/app';
import { AccountService } from './../../_services/account.service';
import { Component, OnInit } from '@angular/core';
import { Task, TaskConstants } from 'src/app/models/task';
import { TableData } from 'src/app/_helpers/table-data';
import { LockAware } from 'src/app/_helpers/lock-aware';
import { TableDialogData } from 'src/app/models/table-dialog-data';
import { GenericNoteDialogComponent } from '../dialogs/generic-note-dialog/generic-note-dialog.component';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-task-overview',
  templateUrl: './task-overview.component.html',
  styleUrls: ['./task-overview.component.scss']
})
export class TaskOverviewComponent extends LockAware implements OnInit {

  tableData = new TableData<Task>(['customer', 'branch', 'createdDate', 'description', 'priority', 'assignee', 'dueDate', 'doneDate', 'action']);
  //tableData = new TableData<Task>(['customer', 'branch', 'createdDate', 'description', 'priority', 'assignee', 'dueDate', 'doneDate']);
  taskPriorities = TaskConstants.Priorities;

  private _filterSubject = new Subject<string>();
  private _customerCode: string = '';
  private _currentView: string = 'my-items';
  private _showCompleted: boolean = false;

  get customerCode(): string {
    return this._customerCode;
  }
  set customerCode(value: string) {
    this._customerCode = value;
    this._filterSubject.next();
  }

  get currentView(): string {
    return this._currentView;
  }
  set currentView(value: string) {
    this._currentView = value;
    this._filterSubject.next();
  }

  get showCompleted(): boolean {
    return this._showCompleted;
  }
  set showCompleted(value: boolean) {
    this._showCompleted = value;
    this._filterSubject.next();
  }

  get user(): AppUser {
    return this._accountService.getCurrentUser();
  }

  constructor(
    private _accountService: AccountService,
    private _allTasksService: AllTasksService,
    private _lockService: LockService,
    private _matSnackBar: MatSnackBar,
    private _dataService: TasksService,
    private _stakeholdersService: StakeholderService,
    private dialog: MatDialog) {
    super('Task', _lockService, _matSnackBar);
    this.reload();
    this._filterSubject.pipe(debounceTime(400)).subscribe(_ => this.reload());
  }

  ngOnInit(): void {
  }

  protected reload(): void {
    const filterAssignee = this.currentView === 'my-items' ? this.user.username : this.currentView === 'diav' ? '@digital-avantgarde.de' : '';
    this._allTasksService.get(this.customerCode?.toUpperCase(), filterAssignee, this.showCompleted).then(tasks => {
      this.tableData.data = tasks;
    });
  }

  openNoteDialog(obj: Task): void {
    this.lockHandler.entityId = obj.id;
    this.lockHandler.branchId = obj.branch.id;
    this.lockHandler.tryGetLock(
      () => {
        this._openNoteDialog('Update', obj);
      },
      () => {
        this._openNoteDialog('View', obj);
      }
    );
  }

  private _openNoteDialog(action: string, obj: Task): void {
    const data: TableDialogData<string> = {
      action: action,
      data: obj.note,
      lockHandler: this.lockHandler,
    };

    const dialogRef = this.dialog.open(GenericNoteDialogComponent, {
      width: '450px',
      data: data,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.event == 'Update') {
        obj.note = result.data;
        this.updateRowData(obj);
      }
      if (this.lockHandler.isLockedForMe) {
        this.lockHandler.releaseLock();
      }
    });
  }

  openDialog(action: string, obj: Task, additional: any = undefined): void {
    if (action === 'Add') {
      this._openDialog(action, obj, additional);
    } else {
      this.lockHandler.entityId = obj.id;
      this.lockHandler.branchId = obj.branch.id;
      this.lockHandler.tryGetLock(
        () => {
          this._openDialog(action, obj, additional);
        },
        () => {
          if (action !== 'Delete') {
            this._openDialog('View', obj, additional);
          }
        }
      );
    }
  }

  private _openDialog(action: string, obj: Task, additional: any = undefined): void {
    this._stakeholdersService.getForBranch(obj.branch.id, obj.branch.customer.id).then(stakeholders => {

      const data: TableDialogData<Task> = {
        action: action,
        data: obj,
        additional: { stakeholders: stakeholders },
        lockHandler: this.lockHandler,
      };
      if (additional) {
        data.additional = additional;
      }
      const dialogRef = this.dialog.open(TasksDialogComponent, {
        width: '450px',
        data: data
      });

      dialogRef.afterClosed().subscribe(result => {
        /*
        if (result?.callback) {
          this.callbackWhenDone = result.callback;
        }
        */
        if (result?.event == 'Update') {
          this.updateRowData(result.data);
        } else if (result?.event == 'Delete') {
          this.deleteRowData(result.data);
        }
        if (this.lockHandler.isLockedForMe) {
          this.lockHandler.releaseLock();
        }
      });
    });

  }

  updateRowData(row_obj: Task) {
    this._dataService.updateForBranch(row_obj.branch, row_obj).then(x => {
      this.tableData.update(x);
    });
  }

  deleteRowData(row_obj: Task) {
    this._dataService.deleteForBranch(row_obj.branch, row_obj.id).then(x => {
      this.tableData.delete(row_obj);
    });
  }
}
