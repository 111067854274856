import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { LockedEntity } from '../models/locked-entity';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AccountService } from './account.service';
import * as signalR from '@microsoft/signalr';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LockService {

  private _locks: LockedEntity[] = [];
  private locksSubject = new BehaviorSubject<LockedEntity[]>(this._locks);
  public locks: Observable<LockedEntity[]>;
  private lockReleaseSubject = new BehaviorSubject<string>(undefined);
  public lockRelease: Observable<string>;
  private hubConnection: signalR.HubConnection
  private baseUrl = `${environment.apiUrl}/locks`;
  private currentBranchId = '';

  private ownLocks: LockedEntity[] = [];
  private updatesSubject = new BehaviorSubject<LockedEntity>(undefined);

  private get allLocks(): LockedEntity[] {
    return this._locks.concat(this.ownLocks);
  }

  constructor(
    private http: HttpClient,
    private _accountService: AccountService
  ) {
    this.locks = this.locksSubject.asObservable();
    this.lockRelease = this.lockReleaseSubject.asObservable();
  }

  public get canLock(): boolean {
    return this._accountService.getCurrentBranch()?.role === 'User';
  }

  public startConnection() {
    const reconnect = [0, 5000, 10000];
    for (let i = 0; i < 100; i++) {
      reconnect.push(10000);
    }
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.wsUrl, {
        transport: signalR.HttpTransportType.WebSockets,
        skipNegotiation: true,
        accessTokenFactory: () => { return this._accountService.getCurrentUser()?.token; }
      })
      .withAutomaticReconnect(reconnect)
      .build();
    this.connect();
    this.addInitializeListener();
    this.addLockedListener();
    this.addReleasedListener();
    this.addUpdateLockListener();
    this.hubConnection.onreconnected(() => {
      this._accountService.branch.subscribe(x => this.subscribeToBranch(x.id, true));
    });
  }

  private connect() {
    this.hubConnection
      .start()
      .then(() => {
        this._accountService.branch.subscribe(x => this.subscribeToBranch(x.id));
      })
      .catch(err => {
        console.log('Error while starting connection: ' + err);
        setTimeout(() => {
          this.connect();
        }, 10000);
      });
  }

  private async subscribeToBranch(branchId: string, reconnect: boolean = false) {
    if (!reconnect && this.currentBranchId === branchId) {
      return;
    }
    if (this.currentBranchId) {
      await this.hubConnection.invoke('UnsubscribeFromBranch', this.currentBranchId);
    }
    this.currentBranchId = branchId;
    if (this.currentBranchId) {
      await this.hubConnection.invoke('SubscribeToBranch', this.currentBranchId);
    }
    this._locks = [];
    this.locksSubject.next(this.allLocks);
  }

  private addInitializeListener() {
    this.hubConnection.on('initialize', (data: LockedEntity[]) => {
      this._locks = data.map(x => new LockedEntity(x));
      this.locksSubject.next(this.allLocks);
    });
  }

  private addLockedListener() {
    this.hubConnection.on('locked', (data: LockedEntity) => {
      this.pushLock(data);
    });
  }

  private addReleasedListener() {
    this.hubConnection.on('released', (data: LockedEntity) => {
      this.removeLock(data);
    });
  }

  private removeLock(data: LockedEntity) {
    const lockedEntity = this._locks.find(x => x.branchId === data.branchId && x.entityId === data.entityId);
    if (lockedEntity) {
      const index = this._locks.indexOf(lockedEntity);
      this._locks.splice(index, 1);
      this.locksSubject.next(this.allLocks);
      this.lockReleaseSubject.next(lockedEntity.entityId);
    }
  }

  private addUpdateLockListener() {
    this.hubConnection.on('updateLock', (data: LockedEntity) => {
      this.updatesSubject.next(data);
    });
  }

  private pushLock(data: LockedEntity) {
    let lockedEntity = this.ownLocks.find(x => x.branchId === data.branchId && x.entityId === data.entityId)
    if(lockedEntity && lockedEntity.lockId) {
      return;
    }

    lockedEntity = this._locks.find(x => x.branchId === data.branchId && x.entityId === data.entityId);
    if(lockedEntity) {
      const index = this._locks.indexOf(lockedEntity);
      this._locks.splice(index, 1);
    }
    this._locks.push(new LockedEntity(data));
    this.locksSubject.next(this.allLocks);
  }

  public isLocked(entityId: string, entityType: string, branchId: string = undefined) {
    if (!this.canLock) {
      return true;
    }
    const branch = this._accountService.getCurrentBranch();
    const lockEntry = this._locks.find(x => x.branchId === (branchId ?? branch.id) && x.entityId === entityId && x.entityType === entityType && x.expiration > new Date());
    if (lockEntry) {
      return;
    } else {
      this.http.get<LockedEntity>(`${this.baseUrl}/branches/${(branchId ?? branch.id)}/${entityType}/${entityId}`).toPromise().then(data => {
        this.pushLock(new LockedEntity(data));
        return;
      }, error => {
        return;
      });
    }
  }

  public getLock(entityId: string, entityType: string, branchId: string = undefined): Promise<LockedEntity> {
    const branch = this._accountService.getCurrentBranch();
    const existingLock = this.ownLocks.find(x => x.branchId === (branchId ?? branch.id) && x.entityId === entityId && x.entityType === entityType && x.expiration > new Date());
    const lockEntry = this._locks.find(x => x.branchId === (branchId ?? branch.id) && x.entityId === entityId && x.entityType === entityType && x.expiration > new Date());
    if (existingLock) {
      return new Promise<LockedEntity>((resolve) => { resolve(existingLock); });
    } else if (lockEntry) {
      return this.verifyLock(lockEntry);
    } else {
      const newLockEntry: LockedEntity = {
        branchId: branchId ?? branch.id,
        entityId: entityId,
        entityType: entityType,
      };
      return new Promise<LockedEntity>((resolve) => {
        this.http.post<LockedEntity>(`${this.baseUrl}`, newLockEntry).toPromise().then(data => {
          const lockedEntity = new LockedEntity(data);
          this.ownLocks.push(lockedEntity);
          this.locksSubject.next(this.allLocks);
          resolve(lockedEntity);
        }, error => {
          resolve(new LockedEntity(error));
        });
      });
    }
  }

  private verifyLock(lockEntry: LockedEntity): Promise<LockedEntity> {
    return new Promise<LockedEntity>((resolve) => {
      this.updatesSubject.pipe(first(x => x && x.entityId === lockEntry.entityId)).subscribe(x => {
        if (x.entityType) {
          resolve(lockEntry)
        } else {
          this.removeLock(lockEntry);
          resolve(null);
        }
      });
      this.hubConnection.invoke('VerifyLock', lockEntry.branchId, lockEntry.entityId, lockEntry.entityType);
    });
  }

  public releaseLock(lockId: string): Promise<any> {
    return new Promise<any>((resolve) => {
      return this.http.delete(`${this.baseUrl}/${lockId}`).toPromise().then(data => {
        const lockedEntity = this.ownLocks.find(x => x.lockId === lockId);
        const index = this.ownLocks.indexOf(lockedEntity);
        const removed = this.ownLocks.splice(index, 1);
        if (removed.length > 0) {
          const lock = removed[0];
          const lockIndex = this._locks.findIndex(x => x.entityType === lock.entityType && x.entityId === lock.entityId);
          if (lockIndex >= 0) {
            this._locks.splice(lockIndex, 1);
          }
        }
        this.locksSubject.next(this.allLocks);
        resolve(data)
      });
    });
  }

  public unload(): Promise<any> {
    const promises = this.ownLocks.map(x => this.http.delete(`${this.baseUrl}/${x.lockId}`).toPromise());
    return Promise.all(promises);
  }
}