import { ServiceClass } from './../_services/service-base';
export class Stakeholder implements ServiceClass {
    public id: string;
    public name: string;
    public firstName: string;
    public title: string;
    public code: string;
    public position: string;
    public mail: string;
    public phone: string;
}
